import {TitleSource} from './title.src.enum';
import {MultilingualStringBuilder, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

/**
 * @author Vahagn.Lazyan
 * @since 3/2/2017
 * @version 1.0.0
 */

export class Title {

  private static findTitleSource(src: string): TitleSource {
    let result: TitleSource;
    if (src === 'custom') {
      result = TitleSource.CUSTOM;
    } else if (src === 'field') {
      result = TitleSource.FIELD;
    } else if (src === 'group') {
      result = TitleSource.GROUP;
    } else if (src === 'msg') {
      result = TitleSource.MESSAGE;
    } else {
      result = TitleSource.NONE;
    }

    return result;
  }

  private _msg?: string;
  private _title_src?: TitleSource;
  private _custom_text?: MultilingualString;

  constructor(msg?: string, title_src?: string, custom_text?: any) {
    this._msg = msg;
    this._title_src = Title.findTitleSource(title_src);
    this._custom_text = this.processCustomText(custom_text);
  }

  private processCustomText(custom_text?: any): MultilingualString {
    if (custom_text !== undefined && custom_text !== null) {
      const multilingualStringBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();
      for (const languageId of Object.keys(custom_text)) {
        multilingualStringBuilder.withValueForLanguage(Number(languageId), custom_text[languageId]);
      }
      return multilingualStringBuilder.build();
    }
    return null;
  }

  get msg(): string {
    return this._msg;
  }

  set msg(value: string) {
    this._msg = value;
  }

  get title_src(): TitleSource {
    return this._title_src;
  }

  set title_src(value: TitleSource) {
    this._title_src = value;
  }

  get custom_text(): MultilingualString {
    return this._custom_text;
  }

  set custom_text(value: MultilingualString) {
    this._custom_text = value;
  }
}
