/**
 * Created by edgar.torosyan on 3/31/17.
 */
import {HttpErrorResponse} from '@angular/common/http';
import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {MatDrawer} from '@angular/material';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {TokenInfoService} from '@synisys/idm-authentication-client-js';
import {
    DePermissionService,
    DeService,
    MainEntity,
} from '@synisys/idm-de-service-client-js';
import {FormService, FormType} from '@synisys/idm-dynamic-layout-interpreter';
import {Template} from '@synisys/idm-export-templates-service-client-js';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {LockingService} from '@synisys/idm-lock-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {ValidationService} from '@synisys/idm-validation-calculation-service-client-js';
import {notNil} from '@synisys/skynet-store-utilities';
import {isNil} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {first, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {noop} from 'rxjs/util/noop';
import {DynamicFormComponent} from './component';
import {AbstractDestructionSubject} from './component/abstract-destruction-subject';
import {ActionNotificationData, ActionNotifierKey} from './concepts';
import {DrawerData} from './concepts/drawer/drawer-data.type';
import {DrawerType} from './concepts/drawer/drawer-type.enum';
import './de.container.component.scss';
import {LockGuard, MetaFormModel} from './service';
import {ActionBuilder, LayoutAction, StateArg} from './service/layout-actions';
import {MetaFormHelperService} from './service/local/meta-form-helper.service';
import {DefaultModelDecoratorProvider} from './service/local/default-model-decorator-provider';

@Component({
    moduleId: module.id + '',
    selector: 'de-container',
    templateUrl: 'de.container.component.html',
})
export class DeContainerComponent extends AbstractDestructionSubject
    implements OnChanges, OnInit, OnDestroy {
    public static LAST_ACTION_COMMENT = 'lastActionComment';
    public drawerTypeEnum = DrawerType;
    @Input()
    public categoryName: string;
    @Input()
    public entityId: number;
    @Input()
    public formName: string;
    @Input()
    public actions: LayoutAction[] = [];
    @Input()
    public formId: number;
    @Input()
    public isInDrawer = false;
    @Input()
    public transientFields: object;
    @Input()
    public parentContainer: DeContainerComponent;
    @ViewChild('drawer')
    public drawer: MatDrawer;
    public isToggled = false;
    public actionNotifier: Subject<ActionNotificationData> = new Subject<
        ActionNotificationData
    >();
    public isReady = false;
    public drawerData: DrawerData<DrawerType> = {};
    public drawerClosable: boolean;
    private _entity: MainEntity;
    private _metaForm: MetaFormModel;
    private _form: DynamicFormComponent;

    constructor(
        private deService: DeService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private kbService: KbService,
        private validationService: ValidationService,
        private languageService: LanguageService,
        private dePermissionService: DePermissionService,
        private formService: FormService,
        private lockService: LockingService,
        private lockGuard: LockGuard,
        private tokenInfoService: TokenInfoService,
        private titleService: Title,
        private metaFormHelperService: MetaFormHelperService,
        @Optional()
        private defaultModelDecoratorProvider: DefaultModelDecoratorProvider
    ) {
        super();
    }

    get entity(): MainEntity {
        return this._entity;
    }

    @Input()
    set entity(entity: MainEntity) {
        this._entity = entity;
        this.initWorkflowActions();
    }

    get metaForm(): MetaFormModel {
        return this._metaForm;
    }

    get form(): DynamicFormComponent {
        return this._form;
    }

    @ViewChild('dynamicForm')
    set form(value: DynamicFormComponent) {
        this._form = value;
        this.initWorkflowActions();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['formName'] ||
            changes['categoryName'] ||
            changes['formId'] ||
            changes['entityId']
        ) {
            if (isNil(changes['entity'])) {
                this.entity = undefined;
            }
            this.isReady = false;
            this.initContainer();
        }
    }

    public ngOnInit(): void {
        this.quickLoginHandler();
    }

    public setIsToggle(opened: boolean): void {
        this.isToggled = opened;
        if (!opened) {
            this.actionNotifier.next(
                new ActionNotificationData(
                    ActionNotifierKey.DRAWER_CLOSE_ACTION,
                    undefined
                )
            );
        }
    }

    private quickLoginHandler(): void {
        this.tokenInfoService.tokenUpdatedViaQuickLogin
            .pipe(
                mergeMap(() =>
                    this.lockGuard.checkForLock(
                        this.categoryName,
                        this.formName,
                        this.entityId,
                        this.formId
                    )
                ),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, console.error);
    }

    private initContainer(): void {
        this.entityInitiation()
            .pipe(
                switchMap(entity => {
                    this.initFunctions();
                    return this.metaFormHelperService.loadFormMetaModel(
                        this.categoryName,
                        entity,
                        this.formName,
                        this.formId,
                        true
                    );
                }),
                first(),
                takeUntil(this.destroySubject$)
            )
            .subscribe(
                (metaFormModel: MetaFormModel) => {
                    this._metaForm = metaFormModel;
                    this.setTitle();
                    this.initWorkflowActions();
                    this.isReady = true;
                },
                err => console.error(err)
            );
    }

    private entityInitiation(): Observable<MainEntity> {
        if (isNil(this.entity)) {
            let entity$: Observable<MainEntity>;
            if (this.entityId === null || this.entityId === undefined) {
                entity$ = this.deService
                    .createBlankEntity(this.categoryName)
                    .pipe(
                        switchMap(value =>
                            this.defaultModelDecoratorProvider
                                ? this.defaultModelDecoratorProvider.decorate(
                                      this.categoryName,
                                      this.formId,
                                      this._activatedRoute.snapshot.params,
                                      this._activatedRoute.snapshot.queryParams,
                                      this.transientFields,
                                      value
                                  )
                                : Observable.of(value)
                        )
                    );
            } else {
                entity$ = this.deService
                    .loadEntityByInstanceId(
                        this.categoryName,
                        this.entityId,
                        true
                    )
                    .catch(error => this.handleDeLoadError(error));
            }

            return entity$.map((entity: MainEntity) => {
                this._entity = entity;
                return this._entity;
            });
        }

        return Observable.of(this.entity);
    }

    private setTitle(): void {
        if (
            !this.isInDrawer &&
            this._metaForm.layoutModel.title &&
            this._metaForm.layoutModel.title.fields.length > 0
        ) {
            this.titleService.setTitle(
                this._metaForm.layoutModel.title.fields
                    .map(metaField => this.entity.getProperty(metaField).value)
                    .join(' ')
            );
        }
    }

    private initFunctions(): void {
        if (this.actions === undefined) {
            this.actions = [];
        }

        if (this.transientFields === undefined) {
            this.transientFields = [];
        }

        this.actions.push(
            new ActionBuilder()
                .name('newEntityPopup')
                .params([
                    StateArg('drawerFormName'),
                    StateArg('drawerCategory'),
                    StateArg('drawerFormId'),
                    StateArg('drawerParams'),
                ])
                .action(
                    (
                        formName: string,
                        categoryName: string,
                        formId: number,
                        params?: object
                    ): void => {
                        this.drawerData = {
                            type: DrawerType.ENTITY,
                            formName: formName,
                            category: categoryName,
                            formId,
                            params,
                            entityId: undefined,
                        };

                        this.formService
                            .loadFormTypeId(formName, formId, categoryName)
                            .pipe(takeUntil(this.destroySubject$))
                            .subscribe(
                                (type: number) => {
                                    this.drawerClosable =
                                        type === FormType.VIEW;
                                    this.drawer.toggle();
                                },
                                err => console.error(err)
                            );
                    }
                )
                .build()
        );

        this.actions.push(
            new ActionBuilder()
                .name('editEntityPopup')
                .params([
                    StateArg('drawerFormName'),
                    StateArg('drawerCategory'),
                    StateArg('drawerFormId'),
                    StateArg('drawerEntityId'),
                    StateArg('drawerParams'),
                ])
                .action(
                    (
                        formName: string,
                        categoryName: string,
                        formId: number,
                        entityId: number,
                        params?: object
                    ): void => {
                        this.lockGuard
                            .checkForLock(
                                categoryName,
                                formName,
                                entityId,
                                formId
                            )
                            .pipe(takeUntil(this.destroySubject$), first())
                            .subscribe(
                                (condition: boolean) => {
                                    if (condition) {
                                        this.drawerData = {
                                            type: DrawerType.ENTITY,
                                            formName: formName,
                                            category: categoryName,
                                            formId,
                                            params,
                                            entityId,
                                        };

                                        this.formService
                                            .loadFormTypeId(
                                                formName,
                                                formId,
                                                categoryName
                                            )
                                            .pipe(
                                                takeUntil(this.destroySubject$)
                                            )
                                            .subscribe(
                                                (type: number) => {
                                                    this.drawerClosable =
                                                        type === FormType.VIEW;
                                                    this.drawer.toggle();
                                                },
                                                err => console.error(err)
                                            );
                                    }
                                },
                                err => console.error(err)
                            );
                    }
                )
                .build()
        );
        this.actions.push(
            new ActionBuilder()
                .name('toggleFromEntityPopup')
                .params([])
                .action(() => {
                    this.parentContainer.drawer.toggle();
                })
                .build()
        );

        this.actions.push(
            new ActionBuilder()
                .name('notifyParentForDrawerSave')
                .params([])
                .action((response: object) => {
                    this.parentContainer.actionNotifier.next(
                        new ActionNotificationData(
                            ActionNotifierKey.DRAWER_SAVE_ACTION,
                            response
                        )
                    );
                })
                .build()
        );

        this.actions.push(
            new ActionBuilder()
                .name('editExportTemplate')
                .params([])
                .action((template: Template): void => {
                    this.drawerData = {
                        type: DrawerType.EXPORT_TEMPLATE,
                        template,
                    };
                    this.drawer.toggle();
                    this.drawerClosable = false;
                })
                .build()
        );
    }

    private handleDeLoadError(error: HttpErrorResponse): Observable<void> {
        switch (error.status) {
            case 404: {
                this._router.navigateByUrl('dynamic-de/deleted');
                break;
            }
            case 423: {
                this._router.navigateByUrl('dynamic-de/locked');
                break;
            }
        }
        return Observable.throw(error);
    }

    private initWorkflowActions(): void {
        if (notNil(this.form) && notNil(this.metaForm) && notNil(this.entity)) {
            this.form.initWorkflowActions();
        }
    }
}
