import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {DeContainerComponent} from '../../de.container.component';

@Component({
    moduleId: module.id + '',
    selector: 'goto-de',
    templateUrl: 'goto-data-entry.component.html',
})
export class GotoDataEntryComponent implements OnInit {
    public entityId: string;
    public categoryName: string;
    public formName: string;
    public formId: number;

    @ViewChild('deContainer')
    public deContainer: DeContainerComponent;

    constructor(private activatedRoute: ActivatedRoute) {}

    public ngOnInit(): void {
        this.activatedRoute.params.subscribe((params: Params) => {
            this.entityId = params['id'];
            this.categoryName = params['categoryName'];
            this.formName = params['formName'];
            this.formId = params['formId'];
        }, console.error);
    }
}
