/**
 * @author Vahagn Lazyan.
 * @since 2.1.0
 */
import {NgModule} from '@angular/core';
import {DeControlsModule} from '@synisys/idm-de-service-client-js';
import {DynamicFormControlsModule} from '@synisys/idm-dynamic-forms-controls';
import {
    ControlsModule,
    ControlsTranslationService,
    MessageService as NgControlsMessageService,
} from '@synisys/idm-ng-controls';
import {
    DePermissionGuard,
    LockGuard,
    OnlineDePermissionGuard,
    OnlineLockGuard,
} from '../service/guards';
import {OfflineDynamicFormsUiModule} from './offline-dynamic-forms-ui.module';
import {WarningOnLeaveGuard} from '../service/guards/impl/warning-on-leave.guard';
import {ConformationDialogComponent} from '../service/guards/impl/conformation-dialog.component';
import {CommonModule} from '@angular/common';
import {MatButtonModule, MatDialogModule} from '@angular/material';
import {MessageService} from '@synisys/idm-message-language-service-client-js';

@NgModule({
    imports: [
        CommonModule,
        ControlsModule,
        DeControlsModule,
        DynamicFormControlsModule,
        OfflineDynamicFormsUiModule,
        MatDialogModule,
        MatButtonModule,
    ],
    declarations: [ConformationDialogComponent],
    entryComponents: [ConformationDialogComponent],
    providers: [
        WarningOnLeaveGuard,
        ControlsTranslationService,
        {provide: NgControlsMessageService, useExisting: MessageService},
        {provide: DePermissionGuard, useClass: OnlineDePermissionGuard},
        {provide: LockGuard, useClass: OnlineLockGuard},
    ],
})
export class DynamicFormsUiModule {}
