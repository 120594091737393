export enum ActionNotifierKey {
    SAVE_ACTION = 1,
    DRAWER_SAVE_ACTION = 2,
    DO_WORKFLOW_ACTION = 3,
    CANCEL_ACTION = 4,
    TAB_CHANGE_ACTION = 5,
    CLOSE_ACTION,
    DRAWER_CLOSE_ACTION,
    SUB_FORM_CLOSE_ACTION,
    SUB_FORM_SAVE_ACTION,
}
