import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ExportDataLoaderService} from '@synisys/idm-dynamic-forms-controls';
import {ExportService} from '@synisys/idm-export-client-js';
import {IExpressionEvaluationService} from '@synisys/idm-xml-template-builder';
import {LanguagesState} from '@synisys/skynet-store-languages-api';
import {Observable} from 'rxjs/Observable';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {first, map, switchMap} from 'rxjs/operators';
import {TemplateBuilderCommunicationService} from './template-builder-communication.service';

@Injectable()
export class ExpressionEvaluationService
    implements IExpressionEvaluationService {
    public constructor(
        private store: Store<LanguagesState>,
        private exportService: ExportService,
        private exportDataLoaderService: ExportDataLoaderService,
        private templateBuilderCommunicationService: TemplateBuilderCommunicationService
    ) {}

    public getEvaluatedExpressionsValues(
        expressions: string[]
        // tslint:disable-next-line:no-any
    ): Observable<Map<string, any>> {
        return this.templateBuilderCommunicationService.getIdentity().pipe(
            first(),
            switchMap(({id, category: categorySystemName}) => {
                return combineLatest(
                    this.exportDataLoaderService.getExportData(
                        categorySystemName,
                        id
                    ),
                    this.store.select(
                        state => state.languages.currentLanguageId
                    )
                ).pipe(
                    map(([data, languageId]) => ({
                        data,
                        languageId,
                        id,
                        categorySystemName,
                    }))
                );
            }),
            first(),
            switchMap(
                ({data, languageId, id: categoryItemId, categorySystemName}) =>
                    this.exportService.evaluate({
                        expressions,
                        categorySystemName,
                        categoryItemId,
                        languageId,
                        data,
                        classifiersSystemNames: [],
                        messageComponentIds: [],
                        timeZoneId: Intl.DateTimeFormat().resolvedOptions()
                            .timeZone,
                    })
            )
        );
    }
}
