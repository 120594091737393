import {Component, Input} from '@angular/core';
import './popup-entity.component.scss';

@Component({
    moduleId: module.id + '',
    selector: 'popupEntityForm',
    templateUrl: 'popup-entity.component.html',
})
export class PopupEntityComponent {
    @Input()
    public parentContainer: any;

    @Input()
    public formName: string;

    @Input()
    public category: string;

    @Input()
    public formId: number;

    @Input()
    public entityId: number;

    @Input()
    public params: object;

    @Input()
    public isToggled: boolean;
}
