import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {ReplaySubject} from 'rxjs/ReplaySubject';

@Injectable()
export class TemplateBuilderCommunicationService implements OnDestroy {
    private identitySubject = new ReplaySubject<{
        id: number;
        category: string;
    }>();

    public setIdentity(id: number, category: string): void {
        this.identitySubject.next({id, category});
    }

    public getIdentity(): Observable<{id: number; category: string}> {
        return this.identitySubject.asObservable();
    }

    public ngOnDestroy(): void {
        this.identitySubject.complete();
    }
}
