import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    ViewRef,
} from '@angular/core';
import {DePermissionService} from '@synisys/idm-de-service-client-js';
import {
    CellModel,
    ControlModel,
    FormType,
    RowModel,
} from '@synisys/idm-dynamic-layout-interpreter';
import {isNil, memoize, some, values} from 'lodash';
import {filter, takeUntil} from 'rxjs/operators';
import {ActionNotifierKey} from '../../concepts';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {DynamicLayout} from '../dynamic-layout';
import {CellStyleTemplate} from './cell.style.template.enum';

@Component({
    moduleId: module.id + '',
    selector: 'cell',
    templateUrl: 'cell.component.html',
})
export class CellComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy, AfterViewInit {
    get styleBlock(): string {
        return this._styleBlock;
    }

    @Input()
    set styleBlock(value: string) {
        this._styleBlock = value;
    }

    public get title(): CellTitle {
        return CellComponent.getTitle(this.cellModel);
    }

    get isCard(): boolean {
        return this._isCard;
    }

    get hasViewPermission(): boolean {
        return this._hasViewPermission;
    }

    set hasViewPermission(value: boolean) {
        this._hasViewPermission = value;
        this.cellVisibility.emit(this._hasViewPermission);
    }

    private static getTitle: (model: CellModel) => CellTitle = memoize(
        CellComponent.createTitle
    );

    private static createTitle(cellModel: CellModel): CellTitle {
        return {
            titleSrc: cellModel.title.titleMessageSource,
            customText: cellModel.title.customText,
            messageId: cellModel.title.titleMessage,
            style:
                cellModel.properties.template === 'card'
                    ? CellStyleTemplate.CARD
                    : CellStyleTemplate.TRANSPARENT,
        };
    }

    @Input()
    public cellModel: CellModel;
    @Input()
    public layout: DynamicLayout;
    @Output()
    public cellVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();
    public initialized = false;
    public isReady = false;
    private _hasViewPermission = true;
    private _isCard = false;
    private _styleBlock: string;

    constructor(
        @Optional() private dePermissionService: DePermissionService,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    public isPage(): boolean {
        return this._styleBlock === 'page';
    }

    public isForm(): boolean {
        return this._styleBlock === 'form';
    }

    public ngOnInit(): void {
        this._isCard = this.cellModel.properties.template === 'card';
        this.hasViewPermission = this.isCellVisible(this.cellModel);
        this.setIfReadonly();
        this.isReady = true;
        if (this.layout.isForm()) {
            this.layout
                .getActionNotifier()
                .pipe(
                    filter(
                        notification =>
                            notification.key ===
                            ActionNotifierKey.DO_WORKFLOW_ACTION
                    ),
                    takeUntil(this.destroySubject$)
                )
                .subscribe(
                    () => {
                        this.setIfReadonly();
                        this.cd.detectChanges();
                    },
                    err => console.error(err)
                );
        }
    }

    public ngAfterViewInit(): void {
        this.initialized = true;
        this.cd.detectChanges();
    }

    public getControlStyle(control: ControlModel): string {
        return control && control.properties.generalProperties.has('style')
            ? control.properties.generalProperties
                  .get('style')
                  ['classes'].join(' ')
            : '';
    }

    private setIfReadonly(): void {
        if (
            this.layout.isForm() &&
            this.cellModel.controlModel &&
            this.checkIfFieldsDefined() &&
            this.layout.getMetaForm().dePermission
        ) {
            const controlFields = new Set(
                Object.keys(this.cellModel.controlModel.fields)
                    .map<MetaFieldDto>(
                        key => this.cellModel.controlModel.fields[key]
                    )
                    .map(field =>
                        field ? field.metaFieldId.systemName : undefined
                    )
            );

            const permissibleFields = this.getPermissibleFields(controlFields);
            let generalProperties = this.cellModel.controlModel.properties
                .generalProperties;
            if (this.layout.getMetaForm().formType === FormType.EDIT) {
                if (
                    this.layout.getEntity().getInstanceId() === null &&
                    controlFields.size ===
                        permissibleFields.controlAddableFields.length
                ) {
                    generalProperties = generalProperties.set('editable', true);
                } else if (
                    controlFields.size !==
                    permissibleFields.controlEditableFields.length
                ) {
                    generalProperties = generalProperties
                        .set('isreadonly', true)
                        .set('isReadonly', true);
                } else {
                    generalProperties = generalProperties.set('editable', true);
                }
            } else if (this.layout.getMetaForm().formType === FormType.VIEW) {
                generalProperties = generalProperties
                    .set('isreadonly', true)
                    .set('isReadonly', true)
                    .set('viewMode', true);
            }
            this.cellModel.controlModel.properties.generalProperties = generalProperties;
        }
    }

    private checkIfFieldsDefined(): boolean {
        return !some(values(this.cellModel.controlModel.fields), isNil);
    }

    private isCellVisible(cellModel: CellModel): boolean {
        if (this.isPage()) {
            return true;
        }
        const isGrid = (cell: CellModel): boolean => !!cell.grid;
        const booleanReducer = (sum, next) => sum || next;
        const getControlFields = (control: ControlModel) => {
            const fields: Set<string> = new Set();
            Object.keys(control.fields)
                .map<MetaFieldDto>(key => control.fields[key])
                .filter(
                    field =>
                        field &&
                        field.metaFieldId &&
                        field.metaFieldId.systemName
                )
                .forEach(field => fields.add(field.metaFieldId.systemName));
            return fields;
        };
        if (isGrid(cellModel)) {
            return cellModel.grid.rows
                .map((row: RowModel) => {
                    return row.cells
                        .map((cell: CellModel) => this.isCellVisible(cell))
                        .reduce(booleanReducer, false);
                })
                .reduce(booleanReducer, false);
        }
        const controlFields: Set<string> = getControlFields(
            cellModel.controlModel
        );
        return this.layout.getEntity().getInstanceId() === null
            ? this.getPermissibleFields(controlFields).controlAddableFields
                  .length === controlFields.size
            : this.getPermissibleFields(controlFields).controlViewableFields
                  .length === controlFields.size;
    }

    private getPermissibleFields(
        controlFields: Set<string>
    ): {
        controlEditableFields: string[];
        controlViewableFields: string[];
        controlAddableFields: string[];
    } {
        const permissions = this.layout.getMetaForm().dePermission;

        return {
            controlEditableFields: !isNil(permissions)
                ? permissions.editableFields.filter((editableField: string) => {
                      return controlFields.has(editableField);
                  })
                : Array.from(controlFields.values()),
            controlViewableFields: !isNil(permissions)
                ? permissions.viewableFields.filter((viewableField: string) => {
                      return controlFields.has(viewableField);
                  })
                : Array.from(controlFields.values()),
            controlAddableFields: !isNil(permissions)
                ? permissions.addableFields.filter((addableField: string) => {
                      return controlFields.has(addableField);
                  })
                : Array.from(controlFields.values()),
        };
    }
}

export interface CellTitle {
    customText: object;
    messageId: string;
    titleSrc: string;
    style: string;
}

interface MetaFieldDto {
    metaFieldId: {systemName: string};
}
