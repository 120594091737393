import {
    AfterViewChecked,
    Compiler,
    Component,
    ComponentRef,
    DoCheck,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Provider,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {TooltipSettings} from '@synisys/idm-ng-controls';
import {
    ControlModel,
    FormType,
    getOptionalProperty,
    getRequiredProperty,
    InterpreterMetaField,
} from '@synisys/idm-dynamic-layout-interpreter';
import {Set} from 'immutable';
import {
    assign,
    forIn,
    includes,
    isBoolean,
    isEmpty,
    isNil,
    isNumber,
    join,
    size,
    split,
    values,
} from 'lodash';

import * as Mustache from 'mustache';
import {takeUntil} from 'rxjs/operators';
import {ControlActionCallGenerator} from '../../service/layout-actions';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {CellTitle} from '../cell';
import {DynamicLayout} from '../dynamic-layout';
import {getContextEntity, isBooleanString} from '../utilities';
import './control.component.scss';
import {MetaFieldId} from '@synisys/idm-kb-service-client-js';
import {ValidationDisplayType} from '@synisys/idm-validation-calculation-service-client-js/src/model/validation-display-type';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';

@Component({
    moduleId: module.id + '',
    selector: 'control',
    templateUrl: 'control.component.html',
})
export class ControlComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy, AfterViewChecked, DoCheck {
    private static makeBackwardCompatible(controlTemplate: string): string {
        // this is done for aot
        const backwardCompatibleTemplate = controlTemplate.replace(
            /(.+)\[id]\s*=\s*['"]%{id}['"](.+)/gm,
            '$1 [id]="\'%{id}\'" $2'
        );
        return backwardCompatibleTemplate;
    }

    private static loadedSummaries = Set<Function>();
    @Input() public layout: DynamicLayout;
    @Input() public cellTitle: CellTitle;
    public isTitleExists = false;
    private _container: ViewContainerRef;
    private _controlModel: ControlModel;
    private componentReference: ComponentRef<object>;
    private currentLanguageId: number;
    private fieldSet: InterpreterMetaField[];
    private _isReady = false;
    private actionCallGenerator = new ControlActionCallGenerator();
    private _styleBlock: string;
    private _tooltipSettings: TooltipSettings | undefined;
    @ViewChild('label')
    private label: ElementRef;
    @ViewChild('text')
    private text: ElementRef;
    private tooltipLeftPosition: number;

    constructor(private compiler: Compiler) {
        super();
    }

    public get tooltipSettings(): TooltipSettings {
        return this._tooltipSettings;
    }

    get styleBlock(): string {
        return this._styleBlock;
    }

    @Input()
    set styleBlock(value: string) {
        this._styleBlock = value;
    }

    @ViewChild('container', {read: ViewContainerRef})
    set container(containerRef: ViewContainerRef) {
        this._container = containerRef;
    }

    get controlModel(): ControlModel {
        return this._controlModel;
    }

    @Input()
    set controlModel(controlModel: ControlModel) {
        this._controlModel = controlModel;
        this.fieldSet = values(controlModel.fields);
    }

    get isReady(): boolean {
        return this._isReady;
    }

    public ngOnInit(): void {
        this.processControl();
        this.checkForTitle();
    }

    public ngDoCheck(): void {
        this.processData();
    }

    public ngAfterViewChecked(): void {
        if (this.label) {
            if (this.label.nativeElement.offsetWidth) {
                this.tooltipLeftPosition =
                    this.label.nativeElement.offsetWidth + 2;
            }
        }
    }

    public ngOnDestroy(): void {
        if (this.componentReference) {
            this.componentReference.destroy();
        }
        if (this._container) {
            this._container.remove();
        }
        super.ngOnDestroy();
    }

    public getRequiredClassIfNeeded(): string {
        if (
            this.isRequired() &&
            this.layout.isForm() &&
            this.layout.getMetaForm().formType === FormType.EDIT
        ) {
            return ' sis-form__label--required';
        } else {
            return '';
        }
    }

    public getValidationMessage(): string {
        if (this.layout.isForm()) {
            if (this.isValidationExists()) {
                for (const validation of this.layout.getValidations()) {
                    if (
                        this.isControlApplicableToFields(
                            validation.getRelatedMetaFieldIds(),
                            this.getDisplayFieldsNames(validation)
                        )
                    ) {
                        return validation
                            .getMessage()
                            .getValue(this.layout.getCurrentLanguage().getId());
                    }
                }
            } else {
                throw new Error(
                    `${this._controlModel.selector} doesn't have fields so has no validation message`
                );
            }
        }
        return '';
    }

    public isValidationExists(): boolean {
        return size(this._controlModel.fields) === 1;
    }

    public isWithTooltip(): boolean {
        return this.tooltipSettings && this.tooltipSettings.isWithTooltip;
    }

    public isPartlyHidden(): boolean {
        return (
            this.label &&
            this.text &&
            this.label.nativeElement.offsetWidth <
                this.text.nativeElement.offsetWidth
        );
    }

    private loadSummary(summary: Function | undefined): void {
        if (
            summary !== undefined &&
            !ControlComponent.loadedSummaries.has(summary)
        ) {
            ControlComponent.loadedSummaries = ControlComponent.loadedSummaries.add(
                summary
            );
            this.compiler['loadAotSummaries'](() => [summary]);
        }
    }

    private processControl(): void {
        this.processData();
        if (this._controlModel.selector) {
            const moduleKeys = this.layout.getMetaForm()
                ? this.layout.getMetaForm().layoutModel.moduleKeys
                : undefined;
            this.layout
                .getModuleRegistryService()
                .findModuleAndControlType(
                    this._controlModel.selector,
                    moduleKeys
                )
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(({moduleType, summary}) => {
                    if (!isNil(summary)) {
                        summary.forEach(s => this.loadSummary(s));
                    }
                    this.layout.addRuntimeComponent(
                        this._controlModel,
                        moduleType,
                        this.componentDecoratorScheme(
                            this._controlModel.metaControl.template,
                            []
                        ),
                        this.cellTitle
                    );
                }, console.error);

            this.layout.readyToDrawComponents
                .pipe(takeUntil(this.destroySubject$))
                .subscribe(() => {
                    this._container.clear();
                    if (this.componentReference) {
                        this.componentReference.destroy();
                    }
                    const cmpFactory = this.layout.getRuntimeComponentFactory(
                        this._controlModel.id
                    );
                    this.componentReference = this._container.createComponent(
                        cmpFactory,
                        undefined,
                        undefined,
                        undefined,
                        this.layout.getRuntimeModuleRef()
                    );
                }, console.error);
        }

        this.initTooltip();
    }

    private checkForTitle(): void {
        if (isNil(this.cellTitle)) {
            this.isTitleExists = false;
        } else if (!isNil(this.cellTitle.messageId)) {
            this.isTitleExists = true;
        } else if (!isNil(this.cellTitle.customText)) {
            let titleNotEmpty = false;
            forIn(this.cellTitle.customText, function(value) {
                if (!isNil(value) && value !== '') {
                    titleNotEmpty = true;
                }
            });
            this.isTitleExists = titleNotEmpty;
        }
    }

    private processData(): void {
        this.currentLanguageId = this.layout.getCurrentLanguage().getId();
    }

    private componentDecoratorScheme(
        controlTemplate: string,
        providers: Provider[]
    ): Component {
        const mappings = this.resolveMappings();
        controlTemplate = ControlComponent.makeBackwardCompatible(
            controlTemplate
        );
        Mustache.parse(controlTemplate, ['%{', '}']);
        const template: string = Mustache.render(controlTemplate, mappings);
        return {
            template: template,
        };
    }

    private resolveMappings(): object {
        let resultMap: object = {};
        if (this._controlModel) {
            resultMap = assign(
                resultMap,
                this.obtainControlFieldsMap(),
                this.obtainActionCalls(),
                this._controlModel.bindings,
                this.obtainControlProperties()
            );
        }
        return resultMap;
    }

    private obtainActionCalls(): {[key: string]: string} {
        const actionsCallObject: {[key: string]: string} = {};

        if (!isEmpty(this._controlModel.actions)) {
            const controlComponent: ControlComponent = this;
            forIn(this._controlModel.actions, function(actionName, actionKey) {
                actionsCallObject[
                    actionKey
                ] = controlComponent.processActionCall(actionName);
            });
        }
        return actionsCallObject;
    }

    private processActionCall(controlActionValue: string): string {
        const funcCalls: string[] = [];
        split(controlActionValue, ';').forEach((actionName: string) => {
            if (this.layout.isForm()) {
                if (includes(actionName, '-async')) {
                    const realActionName = split(actionName, '-', 1)[0];
                    const aptLayoutAction = this.layout
                        .getLayoutActions()
                        .find(action => action.name === realActionName);
                    funcCalls.push(
                        this.actionCallGenerator.generateCall(
                            this.controlModel,
                            aptLayoutAction,
                            true,
                            getContextEntity(this.layout)
                        )
                    );
                } else {
                    const aptLayoutAction = this.layout
                        .getLayoutActions()
                        .find(action => action.name === actionName);
                    funcCalls.push(
                        this.actionCallGenerator.generateCall(
                            this.controlModel,
                            aptLayoutAction,
                            false,
                            getContextEntity(this.layout)
                        )
                    );
                }
            }
        });
        return join(funcCalls, ';');
    }

    private obtainControlFieldsMap(): {[key: string]: string} {
        const fieldsObject: {[key: string]: string} = {};
        const contextEntity = getContextEntity(this.layout);
        forIn(this._controlModel.fields, (fieldValue: object, key: string) => {
            if (fieldValue) {
                fieldsObject[
                    key
                ] = `${contextEntity}.getProperty('${getOptionalProperty(
                    fieldValue,
                    'metaFieldId.systemName'
                )}').value`;

                fieldsObject[key + '-name'] = getRequiredProperty(
                    fieldValue,
                    'metaFieldId.systemName'
                );

                fieldsObject[key + '-category'] = getOptionalProperty(
                    fieldValue,
                    'compoundCategorySystemName'
                );

                fieldsObject[
                    key + '-id'
                ] = `${contextEntity}.getProperty('${getOptionalProperty(
                    fieldValue,
                    'metaFieldId.systemName'
                )}').value === null ? null :
          ${contextEntity}.getProperty('${getRequiredProperty(
                    fieldValue,
                    'metaFieldId.systemName'
                )}').value.getId()`;
            }
        });
        return fieldsObject;
    }

    private obtainControlProperties(): {[key: string]: string | boolean} {
        const propObject: {[key: string]: string | boolean} = {};
        if (
            this._controlModel.properties &&
            this._controlModel.properties.generalProperties
        ) {
            this._controlModel.properties.generalProperties.forEach(
                (propValue: string, key: string) => {
                    if (
                        !isNumber(propValue) &&
                        !isBoolean(propValue) &&
                        !isBooleanString(propValue)
                    ) {
                        propObject[key] = JSON.stringify(propValue);
                    } else if (isBooleanString(propValue)) {
                        propObject[key] = propValue === 'true';
                    } else {
                        propObject[key] = propValue;
                    }
                }
            );
            propObject[
                'disabled'
            ] = this._controlModel.properties.generalProperties.get(
                'disabled',
                false
            ) as string;
            propObject['id'] = this._controlModel.properties.generatedId;
        }
        return propObject;
    }

    private isRequired(): boolean {
        if (this.layout.isForm()) {
            return this.isControlApplicableToFields(
                this.layout.getMetaForm().requiredMetaFieldIds
            );
        } else {
            return false;
        }
    }

    private isControlApplicableToFields(
        metaFieldIds: MetaFieldId[],
        displayFieldNames?: string[]
    ): boolean {
        if (!metaFieldIds) {
            return false;
        }
        return (
            metaFieldIds.find(
                (metaFieldId: MetaFieldId) =>
                    this.fieldSet.some(
                        (field: InterpreterMetaField) =>
                            !isNil(field) &&
                            metaFieldId.getSystemName() ===
                                field.getSystemName() &&
                            metaFieldId.getMetaCategoryId().getSystemName() ===
                                field
                                    .getMetaFieldId()
                                    .getMetaCategoryId()
                                    .getSystemName()
                    ) &&
                    (!displayFieldNames ||
                        displayFieldNames.length === 0 ||
                        displayFieldNames.some(
                            (fieldName: string) =>
                                fieldName === metaFieldId.getSystemName()
                        ))
            ) !== undefined
        );
    }

    private getDisplayFieldsNames(validation: Validation): string[] {
        if (
            validation.getValidationDisplay() &&
            validation.getValidationDisplay().getFields() &&
            validation.getValidationDisplay().getDisplayType() ===
                ValidationDisplayType.FIELDS
        ) {
            const fields = validation
                .getValidationDisplay()
                .getFields()
                .get('fields');
            if (!fields) {
                return [];
            }
            return Array.isArray(fields) ? fields : fields.fields || [];
        }
        return [];
    }

    private initTooltip(): void {
        if (
            this._controlModel.properties &&
            this._controlModel.properties.generalProperties.has(
                'tooltipSettings'
            )
        ) {
            const json = this._controlModel.properties.generalProperties.get(
                'tooltipSettings'
            );
            this._tooltipSettings = TooltipSettings.fromJsonString(
                <string>json
            );
            if (this._tooltipSettings.isWithTooltip) {
                this._tooltipSettings.isWithTooltip = !(
                    isNil(this._tooltipSettings.caption) &&
                    isNil(this._tooltipSettings.content)
                );
            }
        }
    }
}
