import {DynamicLayout} from './dynamic-layout';
import {forIn, isNil, values} from 'lodash';
import {
    ControlModel,
    GridModel,
    InterpreterMetaField,
} from '@synisys/idm-dynamic-layout-interpreter';

export abstract class DynamicFormLayout extends DynamicLayout {
    public countNumberOfControls(): number {
        return (
            this.countGridControls(this.getMetaForm().layoutModel.content) +
            this.countGridControls(this.getMetaForm().layoutModel.footer) +
            (this.getMetaForm().layoutModel.header ? 1 : 0) +
            (this.getMetaForm().layoutModel.subwayMap ? 1 : 0)
        );
    }

    public isControllerRequiredToSet(controller: ControlModel): boolean {
        if (!isNil(controller.fields)) {
            const fieldValues = values(controller.fields);

            if (
                fieldValues.length === 1 &&
                !isNil(fieldValues[0]) &&
                !isNil(fieldValues[0].metaFieldId)
            ) {
                return !isNil(
                    this.getMetaForm().requiredMetaFieldIds.find(
                        metaFieldId =>
                            metaFieldId.getSystemName() ===
                            fieldValues[0].metaFieldId.systemName
                    )
                );
            }
        }

        return false;
    }

    private countGridControls(grid: GridModel): number {
        let sum = 0;
        if (grid) {
            for (const row of grid.rows) {
                for (const cell of row.cells) {
                    if (cell.controlModel) {
                        const controlFieldsNames = this.getControlFieldsNames(
                            cell.controlModel
                        );
                        if (
                            this.isUserHasViewControlPermission(
                                controlFieldsNames
                            ) &&
                            !cell.controlModel.hasDeletedField
                        ) {
                            sum++;
                        }
                    } else if (cell.grid) {
                        sum += this.countGridControls(cell.grid);
                    }
                }
            }
        }
        return sum;
    }

    private getControlFieldsNames(controlModel: ControlModel): Set<string> {
        const result = new Set();

        if (controlModel.fields) {
            forIn(controlModel.fields, (value: InterpreterMetaField) => {
                if (
                    !isNil(value) &&
                    !isNil(value.metaFieldId) &&
                    !isNil(value.metaFieldId.systemName)
                ) {
                    result.add(value.metaFieldId.systemName);
                }
            });
        }
        return result;
    }

    private isUserHasViewControlPermission(
        controlFieldsNames: Set<string>
    ): boolean {
        if (!this.getMetaForm().dePermission) {
            return true;
        }

        return this.getEntity() && this.getEntity().getInstanceId() === null
            ? this.getMetaForm().dePermission.addableFields.filter(
                  addableFieldName => controlFieldsNames.has(addableFieldName)
              ).length === controlFieldsNames.size
            : this.getMetaForm().dePermission.viewableFields.filter(
                  viewableFieldName => controlFieldsNames.has(viewableFieldName)
              ).length === controlFieldsNames.size;
    }
}
