import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {CellModel, RowModel} from '@synisys/idm-dynamic-layout-interpreter';
import {DynamicLayout} from '../dynamic-layout';

@Component({
    moduleId: module.id + '',
    selector: 'row',
    templateUrl: 'row.component.html',
})
export class RowComponent implements OnInit, OnDestroy {
    private static cellColumnCountStyle(cellModel: CellModel): string {
        return cellModel.properties.columnCount
            ? ' mdl-cell--'.concat(
                  cellModel.properties.columnCount.m.toString(),
                  '-col mdl-cell--',
                  cellModel.properties.columnCount.s.toString(),
                  '-col-tablet mdl-cell--',
                  cellModel.properties.columnCount.xs.toString(),
                  '-col-phone '
              )
            : ' mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone ';
    }

    @Input()
    public rowModel: RowModel;
    @Input()
    public layout: DynamicLayout;
    @Output()
    public rowVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();
    public cells: CellModel[];
    private _styleBlock: string;
    private _computedCellClassName: string;
    private _cellsVisibility: boolean[];

    @Input()
    set styleBlock(value: string) {
        this._styleBlock = value;
    }

    get styleBlock(): string {
        return this._styleBlock;
    }

    get cellsVisibility(): boolean[] {
        return this._cellsVisibility;
    }

    public computeCellClass(template: string): string {
        return !(template === 'card') ? this._computedCellClassName : '';
    }

    public setCellVisibility(index: number, visibility: boolean): void {
        this._cellsVisibility[index] = visibility;
        this.rowVisibility.emit(
            visibility || this._cellsVisibility.indexOf(true) !== -1
        );
    }

    public ngOnInit(): void {
        this.cells = this.rowModel.cells;
        this._cellsVisibility = this.cells.map(() => true);
        this._computedCellClassName = `sis-${this._styleBlock}-grid__column`;
    }

    public ngOnDestroy(): void {}

    public isCellVisible(cellModel: CellModel): boolean {
        return cellModel.properties.visibility
            ? cellModel.properties.visibility.predicate
                ? cellModel.properties.visibility.predicate(
                      this.layout.getPredicateContext()
                  )
                : true
            : true;
    }

    public getCellStyle(cellModel: CellModel): string {
        const template: string =
            cellModel.properties.template.toLowerCase() === 'card'
                ? 'sis__card'
                : ' ';
        return 'mdl-cell '.concat(
            template,
            RowComponent.cellColumnCountStyle(cellModel),
            cellModel.properties.style.has('classes')
                ? (<string[]>cellModel.properties.style.get('classes')).join(
                      ' '
                  )
                : ''
        );
    }

    public trackByFunc(index: number, cell: CellModel): string {
        return cell.id;
    }
}
