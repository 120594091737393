import {Column} from './column.model';
import {Control} from './control.model';
import {Title} from './title.model';

/**
 * Created by edgar.torosyan on 7/19/17.
 */
export class Table {
  private _cols: Column[];
  private _isRemovable: boolean;
  private _isAddable: boolean;
  private _isEditable: boolean;
  private _isWithPopup: boolean;
  private _subEntitySystemName: string;
  private _classifierSystemName: string;
  private _itemsFieldName: string;
  private _subFormKey: string;

  constructor(cols: Column[], isRemovable: boolean, isAddable: boolean, isEditable: boolean,
              subEntitySystemName: string, classifierSystemName: string, itemsFieldName: string,
              isWithPopup: boolean, subFormKey: string) {
    this.processCols(cols);
    this._isRemovable = isRemovable;
    this._isAddable = isAddable;
    this._isEditable = isEditable;
    this._subEntitySystemName = subEntitySystemName;
    this._classifierSystemName = classifierSystemName;
    this._itemsFieldName = itemsFieldName;
    this._isWithPopup = isWithPopup;
    this._subFormKey = subFormKey;
  }

  private processCols(cols: any[]) {
    this._cols = [];
    if (cols) {
      cols.map((col: any) => {
        let title = null;
        if (col['title']) {
          title = new Title(
            col['title']['title_msg'] || null,
            col['title']['title_src'] || null,
            col['title']['custom_text'] || null,
          );
        }

        this._cols.push(
          new Column(title,
            new Control(
              col['control']['selector'],
              col['control']['bindings'],
              col['control']['fields'],
              col['control']['properties'],
            ),
          ),
        );
      });
    }
  }

  get cols(): Column[] {
    return this._cols;
  }

  set cols(value: Column[]) {
    this._cols = value;
  }

  get isAddable(): boolean {
    return this._isAddable;
  }

  set isAddable(value: boolean) {
    this._isAddable = value;
  }

  get subEntitySystemName(): string {
    return this._subEntitySystemName;
  }

  set subEntitySystemName(value: string) {
    this._subEntitySystemName = value;
  }

  get classifierSystemName(): string {
    return this._classifierSystemName;
  }

  set classifierSystemName(value: string) {
    this._classifierSystemName = value;
  }

  get isRemovable(): boolean {
    return this._isRemovable;
  }

  set isRemovable(value: boolean) {
    this._isRemovable = value;
  }

  get itemsFieldName(): string {
    return this._itemsFieldName;
  }

  set itemsFieldName(value: string) {
    this._itemsFieldName = value;
  }

  get isWithPopup(): boolean {
    return this._isWithPopup;
  }

  set isWithPopup(value: boolean) {
    this._isWithPopup = value;
  }

  get subFormKey(): string {
    return this._subFormKey;
  }

  set subFormKey(value: string) {
    this._subFormKey = value;
  }

  get isEditable(): boolean {
    return this._isEditable;
  }

  set isEditable(value: boolean) {
    this._isEditable = value;
  }
}
