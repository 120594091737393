import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ExportTemplatesService,
    PersonalizedTemplate,
    Template,
} from '@synisys/idm-export-templates-service-client-js';
import {cloneDeep} from 'lodash';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {first, map, tap} from 'rxjs/operators';
import {noop} from 'rxjs/util/noop';
import {createLogger} from '../utilities';
import {TemplateBuilderCommunicationService} from './template-builder-communication.service';
import './dynamic-template-editor.component.scss';

const LOGGER = createLogger('dynamic-template-editor');

@Component({
    moduleId: module.id + '',
    selector: 'dynamic-template-editor',
    templateUrl: 'dynamic-template-editor.component.html',
})
export class DynamicTemplateEditorComponent implements OnInit {
    @Input()
    public category: string;
    @Input()
    public instanceId: number;
    @Input()
    public opened: boolean;
    @Output()
    public closeDrawer = new EventEmitter<void>();

    private _template: Template;

    get template(): Template {
        return this._template;
    }

    @Input()
    set template(value: Template) {
        this._template = cloneDeep(value);
    }

    private templateToSave: PersonalizedTemplate | undefined;

    constructor(
        private templateBuilderCommunicationService: TemplateBuilderCommunicationService,
        private exportTemplatesService: ExportTemplatesService
    ) {}

    public ngOnInit(): void {
        this.templateBuilderCommunicationService.setIdentity(
            this.instanceId,
            this.category
        );
    }

    public templateChange(item: Template): void {
        if (item instanceof PersonalizedTemplate) {
            this.templateToSave = item;
        } else {
            LOGGER.error(
                `sis-multilingual-template-editor's emitted ` +
                    `value is not PersonalizedTemplate`
            );
        }
    }

    public onClose(): void {
        this.close();
    }

    public onSaveEvent(): void {
        this.save()
            .pipe(first())
            .subscribe(noop, LOGGER.error);
    }

    public onSaveCloseEvent(): void {
        this.save()
            .pipe(
                first(),
                tap(() => this.close())
            )
            .subscribe(noop, LOGGER.error);
    }

    private save(): Observable<void> {
        if (this.templateToSave) {
            return this.exportTemplatesService
                .updatePersonalizedTemplate(this.templateToSave)
                .pipe(
                    map(template => {
                        this._template = template;
                        this.templateToSave = undefined;
                    })
                );
        }
        return of(undefined);
    }

    private close(): void {
        this.closeDrawer.emit();
    }
}
