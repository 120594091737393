/**
 * @author Vahagn.Lazyan
 * @since 3/7/2017
 * @version
 */

export enum CellStyleTemplate {
    CARD = 'sis-form-toolbar__title',
    TRANSPARENT = 'sis-form__label',
}
