import {
    ChangeDetectorRef,
    Compiler,
    Component,
    ElementRef,
    Inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChange,
    Type,
    ViewChild,
} from '@angular/core';
import {AfterViewChecked} from '@angular/core/src/metadata/lifecycle_hooks';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {ClassifierView} from '@synisys/idm-classifier-service-client-js';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    DePermission,
    DeSerializationService,
    DeService,
    MainEntity,
} from '@synisys/idm-de-service-client-js';
import {
    ControlModel,
    formModuleRegistryKey,
    FormService,
    FormType,
    ModuleRegistryService,
    RowModel,
    VisibilityConditionContext,
} from '@synisys/idm-dynamic-layout-interpreter';
import {KbService, MetaField} from '@synisys/idm-kb-service-client-js';
import {LockingService} from '@synisys/idm-lock-service-client-js';
import {
    LanguageService,
    MessageService,
} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {
    ActionDto,
    ActionService,
} from '@synisys/idm-workflow-service-client-js';
import {concat} from 'lodash';
import {Debounce} from 'lodash-decorators';

import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {first, takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {ActionNotificationData, NotificationModel} from '../../concepts';
import {FormRuntimeContext} from '../../concepts/runtime-context.interface';
import {
    ActionBuilder,
    ContextArg,
    ContextArgEnum,
    LayoutAction,
} from '../../service/layout-actions';
import {
    ActionResolverService,
    DynamicFormActions,
    DynamicFormClassifiersService,
    DynamicFormDocumentsService,
    DynamicFormInterface,
    DynamicFormKbService,
    DynamicFormMainDeService,
    DynamicFormPopupsService,
    DynamicFormUtilitiesService,
    TemporaryDocument,
} from '../../service/local';
import {DynamicFormNavigationsService} from '../../service/local/dynamic-form-navigations.service';
import {MetaFormModel} from '../../service/model/meta-form.model';
import {DynamicLayoutType} from '../dynamic-layout';
import {PopupSubFormComponent} from '../popup/popup-sub-form.component';
import './form.component.scss';
import {noop} from 'rxjs/util/noop';
import {DynamicFormWorkflowService} from '../../service/local/dynamic-form-workflow.service';
import {EntityComparisonHelper} from '@synisys/idm-de-core-frontend/app/shared/impl/helper/entity-comparison-helper';
import {CellTitle} from '../cell/cell.component';
import {DynamicFormLayout} from '../dynamic-form-layout';
import {combineLatest} from 'rxjs/observable/combineLatest';
import {FormActions} from '@synisys/idm-dynamic-forms-controls';

@Component({
    moduleId: module.id + '',
    selector: 'dynamic-form',
    templateUrl: 'form.component.html',
    providers: [
        DynamicFormClassifiersService,
        DynamicFormPopupsService,
        DynamicFormMainDeService,
        DynamicFormDocumentsService,
        DynamicFormNavigationsService,
        DynamicFormUtilitiesService,
        DynamicFormWorkflowService,
        DynamicFormKbService,
        ActionResolverService,
    ],
})
export class DynamicFormComponent extends DynamicFormLayout
    implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
    get isHeaderHidden(): boolean {
        return this._isHeaderHidden;
    }

    get thisRef(): DynamicFormComponent {
        return this;
    }

    get inDrawer(): boolean {
        return this._inDrawer;
    }

    @Input()
    set inDrawer(value: boolean) {
        this._inDrawer = value;
    }

    get languages(): Language[] {
        return this.metaForm.languages;
    }

    get currentLanguage(): Language {
        return this._currentLanguage;
    }

    get workflowActions(): ActionDto[] {
        return this._workflowActions;
    }

    get isReady(): boolean {
        return this._isReady;
    }

    get categorySystemName(): string {
        return this.categoryName;
    }

    get queryParams(): object {
        return this._queryParams;
    }

    public static QUERY_PARAMS: object | undefined = undefined;

    @ViewChild('header') public elementView: ElementRef;
    public headerHeight = 0;
    @Input()
    public layoutActions: LayoutAction[] = [];
    @Input()
    public entity: MainEntity;
    @Input()
    public transientFields: object;
    @Input()
    public actionNotifier: Subject<ActionNotificationData>;
    @Input()
    public metaForm: MetaFormModel;
    @Input()
    public containerType: string | undefined = undefined;
    public activePopup: PopupSubFormComponent;
    public popupEditMode: boolean;
    public formLoadingShow = false;
    public formLoadingSuccess = false;
    public formLoadingFail = false;
    public headerToggled = false;
    public temporaryDocuments: Set<number> = new Set();
    public temporaryDocumentsOld: TemporaryDocument[] = [];
    public oldEntity: MainEntity;
    public emptyEntity: MainEntity;
    public actions: DynamicFormActions = {} as DynamicFormActions;
    private lastScroll = 0;
    private _validations: Validation[] = [];
    private _isHeaderHidden = false;
    private _inDrawer = false;
    private _currentLanguage: Language;
    private _isReady = false;
    private _workflowActions: ActionDto[] = [];
    private _tabJustChanged = false;
    private _queryParams: object;
    private categoryName: string;
    private notification: NotificationModel;
    private entityComparisionHelper: EntityComparisonHelper;

    constructor(
        @Inject(LanguageService)
        private readonly languageService: LanguageService,
        private readonly currentLanguageProvider: CurrentLanguageProvider,
        private readonly _actionService: ActionService,
        private readonly formService: FormService,
        public readonly dialog: MatDialog,
        private readonly activatedRouter: ActivatedRoute,
        private readonly lockService: LockingService,
        private readonly actionResolverService: ActionResolverService,
        protected readonly injector: Injector,
        private readonly changeDetector: ChangeDetectorRef,
        protected readonly compiler: Compiler,
        private readonly messageService: MessageService,
        private readonly deSerializationService: DeSerializationService,
        private readonly _deService: DeService,
        private kbService: KbService,
        @Inject(formModuleRegistryKey)
        public readonly moduleRegistryService: ModuleRegistryService
    ) {
        super();
        this.entityComparisionHelper = new EntityComparisonHelper(kbService);
    }

    public setMainEntityValidations(validations: Validation[]): void {
        this._validations = validations;
        this.validationsChanged.next(this._validations);
    }

    public getValidations(): Validation[] {
        return this._validations;
    }

    public setPopupValidations(validations: Validation[]): void {
        this._validations = validations;
    }

    public getEntity(): Entity {
        return this.entity;
    }

    public ngOnInit(): void {
        this._queryParams = Object.assign(
            {},
            this.activatedRouter.snapshot.queryParams
        );
        if (!this._queryParams.hasOwnProperty('isOffline')) {
            this._queryParams['isOffline'] = 'false';
        }
        DynamicFormComponent.QUERY_PARAMS = this._queryParams;
        this.categoryName = this.metaForm.layoutModel.category;

        this.emptyEntity = this.entity.clone();
        this.oldEntity = this.entity.clone();
        this.initCurrentLanguage()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(
                currentLanguage => {
                    this._currentLanguage = currentLanguage;
                    this.initActions();
                    this._isReady = true;
                },
                err => console.error(err)
            );
    }

    public ngAfterViewChecked(): void {
        if (
            this._isReady &&
            this.elementView &&
            this.elementView.nativeElement &&
            this.elementView.nativeElement.offsetHeight &&
            this.headerHeight !== this.elementView.nativeElement.offsetHeight
        ) {
            this.headerHeight = this.elementView.nativeElement.offsetHeight;
            this.changeDetector.detectChanges();
        }
    }

    public getControlStyle(control: ControlModel): string {
        return control && control.properties.generalProperties.has('style')
            ? control.properties.generalProperties
                  .get('style')
                  ['classes'].join(' ')
            : '';
    }

    public getCurrentLanguage(): Language {
        return this._currentLanguage;
    }

    public showHideHeader(event: Event): void {
        const targetElement: Element = <Element>event.target;
        this._isHeaderHidden =
            targetElement.scrollTop > 68 &&
            targetElement.scrollTop > this.lastScroll;
        this.lastScroll = targetElement.scrollTop;
    }

    public ngOnChanges(changes: {[propName: string]: SimpleChange}): void {
        if (changes['entity']) {
            this.initEnvironment();
        }
        if (changes['metaForm']) {
            this.initEnvironment();
        }
    }

    public isForm(): boolean {
        return true;
    }

    public ngOnDestroy(): void {
        this.removeLock().subscribe(noop, console.error);
        if (this._runtimeRef) {
            this._runtimeRef.destroy();
        }
        super.ngOnDestroy();
    }

    public removeLock(): Observable<boolean> {
        const entityInstanceId: number = this.entity.getInstanceId();
        if (
            entityInstanceId !== null &&
            entityInstanceId !== undefined &&
            this.metaForm.formType === FormType.EDIT
        ) {
            return this.lockService.removeLock(
                this.metaForm.layoutModel.category,
                entityInstanceId
            );
        }
        return of(true);
    }

    public toggleHeader(): void {
        this.headerToggled = !this.headerToggled;
    }

    public showNotification(
        messageKey: string,
        mode: string,
        modeMessageKey: string
    ): void {
        if (!this.notification) {
            this.notification = new NotificationModel();
        }
        this.notification.mode = mode;
        const messages$ = combineLatest([
            this.messageService.getMessage(messageKey),
            this.messageService.getMessage(modeMessageKey),
        ]);

        messages$
            .pipe(first())
            .subscribe(([msg, modeMsg]: [string, string]) => {
                this.notification.message = msg;
                this.notification.modeText = modeMsg;
                this.notification.show();
            }, console.error);
    }

    public showNotificationWithPlaceHolder(
        messageKey: string,
        mode: string,
        placeholders: string[],
        modeMessageKey: string
    ): void {
        if (!this.notification) {
            this.notification = new NotificationModel();
        }
        this.notification.mode = mode;

        const messages$ = combineLatest([
            this.messageService.getMessageWithPlaceholder(
                messageKey,
                placeholders
            ),
            this.messageService.getMessage(modeMessageKey),
        ]);

        messages$
            .pipe(first())
            .subscribe(([msg, modeMsg]: [string, string]) => {
                this.notification.message = msg;
                this.notification.modeText = modeMsg;
                this.notification.show();
            }, console.error);
    }

    public addRuntimeComponent(
        controlModel: ControlModel,
        moduleType: Type<object>,
        component: Component,
        cellTitle?: CellTitle
    ): void {
        const layout: DynamicFormComponent = this;
        this._moduleSet.add(moduleType);
        /* tslint:disable:max-classes-per-file*/
        const cmpClass: Type<object> = class RuntimeComponent
            implements FormRuntimeContext {
            public category = layout.metaForm.layoutModel.category;
            public form = layout;
            public queryParams = layout._queryParams;
            public actions = layout.actions;
            public languages = layout.languages;
            // public item = item;
            public cellId = controlModel.id;
            public title = cellTitle;
            public controlId = controlModel.id;
            public transientFields = layout.transientFields;
            public actionNotifier = layout.actionNotifier;
            public popupEditMode = layout.popupEditMode;
            public contextCategory = this.category;
            public layoutType = DynamicLayoutType.form;
            public formType = layout.metaForm.formType;
            public isRequired = layout.isControllerRequiredToSet(controlModel);
            public containerType = layout.containerType;

            get filters(): object {
                return layout.activatedRouter.snapshot.queryParams['filters'];
            }

            get workflowActions(): ActionDto[] {
                return this.form.workflowActions;
            }

            get entity(): MainEntity {
                return this.form.entity;
            }

            get dePermissionModel(): DePermission {
                return this.form.metaForm.dePermission;
            }

            get contextEntity(): Entity {
                return this.form.entity;
            }

            get validations(): Validation[] {
                return this.form._validations;
            }

            get currentLanguageId(): number {
                return layout.currentLanguage.getId();
            }
        };
        /* tslint:enable:max-classes-per-file*/
        Component(component)(cmpClass);

        this._componentCache.set(controlModel.id, cmpClass);

        if (this._componentCache.size === this._countOfControls) {
            this.loadRuntimeModule();
        }
    }

    public getLayoutActions(): LayoutAction[] {
        return this.layoutActions;
    }

    public getActionNotifier(): Subject<ActionNotificationData> {
        return this.actionNotifier;
    }

    public getPredicateContext(): VisibilityConditionContext {
        return {
            entity: this.entity,
            actions: this.actions,
            item: undefined,
            popupEditMode: this.popupEditMode,
            queryParams: this._queryParams,
            isEditForm: this.metaForm.formType === FormType.EDIT,
        };
    }

    public getInitialTabId(): number {
        let tabId: number;
        if (this.inDrawer) {
            tabId = this.transientFields['tabId'];
        }
        return tabId;
    }

    public getMetaForm(): MetaFormModel {
        return this.metaForm;
    }

    public isSubForm(): boolean {
        return false;
    }

    public dynamicLayoutType(): DynamicLayoutType {
        return DynamicLayoutType.form;
    }

    public getModuleRegistryService(): ModuleRegistryService {
        return this.moduleRegistryService;
    }

    public trackByFunc(index: number, row: RowModel): string {
        return row.id;
    }

    public noChanges(): Observable<boolean> {
        if (this.metaForm.formType === FormType.EDIT) {
            return Observable.fromPromise(
                this.entityComparisionHelper.equals(
                    this.metaForm.layoutModel.category,
                    this.entity,
                    this.oldEntity
                )
            );
        }
        return Observable.of(true);
    }

    public resetEntity(): void {
        this.entity = this.oldEntity.clone();
    }

    public cleanEntity(): void {
        this.entity = this.emptyEntity.clone();
    }

    @Debounce(50, {leading: false})
    public initWorkflowActions(): void {
        const wfStateMetaField: MetaField = this.metaForm.wfStateMetaField;
        if (!wfStateMetaField) {
            this._workflowActions = [];
            return;
        }
        const wfState = this.entity.getProperty<ClassifierView>(
            wfStateMetaField.getSystemName()
        );
        if (wfState.value === null) {
            return;
        }
        this._deService
            .getPermittedActions(
                this.metaForm.layoutModel.category,
                this.entity.getInstanceId()
            )
            .pipe(
                tap(
                    (actionDtos: ActionDto[]) =>
                        (this._workflowActions = actionDtos)
                ),
                takeUntil(this.destroySubject$)
            )
            .subscribe(noop, console.error);
    }

    public tabJustChanged(): void {
        this._tabJustChanged = true;
    }

    public isTabJustChanged(): boolean {
        return this._tabJustChanged;
    }

    public resetTabJustChangedFlag(): void {
        this._tabJustChanged = false;
    }

    public resetForm(): void {
        this.initEnvironment();
        this._isReady = false;
        setTimeout(() => (this._isReady = true));
    }

    private initActions(): void {
        this.layoutActions = concat(
            this.layoutActions,
            this.actionResolverService.getAction()
        );

        this.layoutActions.push(
            new ActionBuilder()
                .name('isViewForm')
                .params([])
                .action(() => this.metaForm.formType === FormType.VIEW)
                .build()
        );

        this.layoutActions.push(
            new ActionBuilder()
                .name('nop')
                .params([])
                .action(() => {})
                .build()
        );
        this.layoutActions.push(
            new ActionBuilder()
                .name('toggleHeader')
                .params([])
                .action(() => {
                    this.toggleHeader();
                })
                .build()
        );

        this.layoutActions.push(
            new ActionBuilder()
                .name('validationEmittedFor')
                .params([ContextArg(ContextArgEnum.CONTROLLER_ID)])
                .action((controlId: string) => {})
                .build()
        );

        this.layoutActions.forEach((layoutAction: LayoutAction) => {
            this.actions[layoutAction.name] = layoutAction.func;
        });
    }

    private initCurrentLanguage(): Observable<Language> {
        return this.currentLanguageProvider.getCurrentLanguage();
    }

    private initEnvironment(): void {
        this._countOfControls = this.countNumberOfControls();
        this._moduleSet = new Set();
        this._componentCache = new Map();
    }
}
