import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {DynamicLayoutModule} from './dynamic-layout.module';
import {DynamicPageComponent} from './component/page';

@NgModule({
    imports: [CommonModule, FormsModule, DynamicLayoutModule, MaterialModule],
    declarations: [DynamicPageComponent],
    exports: [DynamicPageComponent],
})
export class DynamicPageModule {}
