/**
 * Created by edgar.torosyan on 5/19/17.
 */
import {
    Compiler,
    Component,
    Injector,
    Input,
    NgModuleRef,
    OnInit,
    Type,
} from '@angular/core';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    DePermission,
    DePermissionService,
    MainEntity,
} from '@synisys/idm-de-service-client-js';
import {
    ControlModel,
    FormService,
    FormType,
    ModuleRegistryService,
    VisibilityConditionContext,
} from '@synisys/idm-dynamic-layout-interpreter';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {ActionDto} from '@synisys/idm-workflow-service-client-js';
import {first, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {ActionNotificationData} from '../../concepts/action-notification-data.model';
import {FormRuntimeContext} from '../../concepts/runtime-context.interface';
import {LayoutAction} from '../../service/layout-actions/layout-action.builder';
import {MetaFormModel} from '../../service/model/meta-form.model';
import {DynamicLayoutType} from '../dynamic-layout';
import {DynamicFormComponent} from '../form/form.component';
import './sub-form.component.scss';
import {CellTitle} from '../cell/cell.component';
import {DynamicFormLayout} from '../dynamic-form-layout';
import {MetaFormHelperService} from '../../service/local/meta-form-helper.service';

@Component({
    moduleId: module.id + '',
    selector: 'dynamic-sub-form',
    templateUrl: 'sub-form.component.html',
})
export class DynamicSubFormComponent extends DynamicFormLayout
    implements OnInit {
    @Input()
    public categoryName: string;
    @Input()
    public subFormKey: string;
    @Input()
    public id: string;
    @Input()
    public subFormId: number;
    @Input()
    public item: Entity;
    private _metaForm: MetaFormModel;
    private _form: DynamicFormComponent;
    private _isReady = false;

    constructor(
        protected readonly compiler: Compiler,
        protected readonly injector: Injector,
        private readonly _formService: FormService,
        private readonly _kbService: KbService,
        private readonly _dePermissionService: DePermissionService,
        private readonly _languageService: LanguageService,
        private readonly _metaFormHelperService: MetaFormHelperService
    ) {
        super();
    }

    @Input()
    set form(value: DynamicFormComponent) {
        this._form = value;
    }

    get isReady(): boolean {
        return this._isReady;
    }

    get thisRef(): DynamicSubFormComponent {
        return this;
    }

    get metaForm(): MetaFormModel {
        return this._metaForm;
    }

    public ngOnInit(): void {
        this._metaFormHelperService
            .loadFormMetaModel(
                this.categoryName,
                this.item,
                this.subFormKey,
                this.subFormId,
                false
            )
            .pipe(first(), takeUntil(this.destroySubject$))
            .subscribe(
                metaForm => {
                    this._metaForm = metaForm;
                    // this._form.subEntitiesRequiredMetaFields.set(this.categoryName, metaForm.requiredMataFields);
                    this._countOfControls = this.countNumberOfControls();
                    this._isReady = true;
                },
                err => console.error(err)
            );
    }

    public get currentLanguage(): Language {
        return this._form.currentLanguage;
    }

    public getCurrentLanguage(): Language {
        return this._form.currentLanguage;
    }

    public getLayoutActions(): LayoutAction[] {
        return this._form.layoutActions;
    }

    public isForm(): boolean {
        return true;
    }

    public getEntity(): Entity {
        return this.item;
    }

    public getActionNotifier(): Subject<ActionNotificationData> {
        return this._form.actionNotifier;
    }

    public addRuntimeComponent(
        controlModel: ControlModel,
        moduleType: Type<object>,
        component: Component,
        cellTitle?: CellTitle
    ): void {
        const layout: DynamicFormComponent = this._form;
        const subLayout: DynamicSubFormComponent = this;
        const item: Entity = this.item;
        const subFormModel = this._metaForm;
        this._moduleSet.add(moduleType);
        /* tslint:disable:max-classes-per-file*/
        const cmpClass: Type<object> = class RuntimeComponent
            implements FormRuntimeContext {
            public category = layout.metaForm.layoutModel.category;
            public form = layout;
            public queryParams = layout.queryParams;
            public actions = layout.actions;
            public languages = layout.languages;
            public title = cellTitle;
            public item = item;
            public cellId = controlModel.id;
            public controlId = controlModel.id;
            public transientFields = layout.transientFields;
            public actionNotifier = layout.actionNotifier;
            public popupEditMode = layout.popupEditMode;
            public contextCategory = subFormModel.layoutModel.category;
            public layoutType = DynamicLayoutType.subForm;
            public formType = subFormModel.formType;
            public isRequired = subLayout.isControllerRequiredToSet(
                controlModel
            );
            public containerType = layout.containerType;

            get workflowActions(): ActionDto[] {
                return this.form.workflowActions;
            }

            get entity(): MainEntity {
                return this.form.entity;
            }

            get dePermissionModel(): DePermission {
                return this.form.metaForm.dePermission;
            }

            get contextEntity(): Entity {
                return this.item;
            }

            get validations(): Validation[] {
                return this.form.getValidations();
            }

            get currentLanguageId(): number {
                return layout.currentLanguage.getId();
            }
        };
        /* tslint:enable:max-classes-per-file*/
        Component(component)(cmpClass);

        this._componentCache.set(controlModel.id, cmpClass);

        if (this._componentCache.size === this._countOfControls) {
            this.loadRuntimeModule();
        }
    }

    public getPredicateContext(): VisibilityConditionContext {
        return {
            entity: this._form.entity,
            actions: this._form.actions,
            item: this.item,
            popupEditMode: this._form.popupEditMode,
            queryParams: this._form.queryParams,
            isEditForm: this._form.metaForm.formType === FormType.EDIT,
        };
    }

    public getModuleRegistryService(): ModuleRegistryService {
        return this._form.moduleRegistryService;
    }

    public getRuntimeModuleRef(): NgModuleRef<object> {
        return this._runtimeRef;
    }

    public getInitialTabId(): number {
        return undefined;
    }

    public getMetaForm(): MetaFormModel {
        return this._metaForm;
    }

    public isSubForm(): boolean {
        return true;
    }

    public dynamicLayoutType(): DynamicLayoutType {
        return DynamicLayoutType.subForm;
    }

    public getValidations(): Validation[] {
        return this._form.getValidations();
    }
}
