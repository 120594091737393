import {
    Compiler,
    Component,
    Injector,
    Input,
    OnInit,
    Type,
} from '@angular/core';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {
    DePermission,
    DePermissionService,
    MainEntity,
} from '@synisys/idm-de-service-client-js';
import {
    ControlModel,
    FormService,
    FormType,
    ModuleRegistryService,
    VisibilityConditionContext,
} from '@synisys/idm-dynamic-layout-interpreter';
import {KbService} from '@synisys/idm-kb-service-client-js';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {
    Validation,
    ValidationService,
} from '@synisys/idm-validation-calculation-service-client-js';
import {
    ActionDto,
    ActionService,
} from '@synisys/idm-workflow-service-client-js';
import {Observable} from 'rxjs/Observable';
import {first, map} from 'rxjs/operators';
import {Subject} from 'rxjs/Subject';
import {noop} from 'rxjs/util/noop';
import {ActionNotificationData} from '../../concepts';
import {FormRuntimeContext} from '../../concepts/runtime-context.interface';
import {LayoutAction} from '../../service';
import {MetaFormHelperService} from '../../service/local/meta-form-helper.service';
import {MetaFormModel} from '../../service/model';
import {CellTitle} from '../cell/cell.component';
import {DynamicFormLayout} from '../dynamic-form-layout';
import {DynamicLayoutType} from '../dynamic-layout';
import {DynamicFormComponent} from '../form';

@Component({
    moduleId: module.id + '',
    selector: 'dynamic-workflow-form',
    templateUrl: 'workflow-form.component.html',
    styleUrls: ['workflow-form.component.css'],
})
export class WorkflowFormComponent extends DynamicFormLayout implements OnInit {
    @Input()
    public id: string;
    @Input()
    public formId: number;
    @Input()
    public formKey: string;
    @Input()
    public categoryName: string;
    @Input()
    public entity: Entity;
    @Input()
    public actionId: number;
    public actionName$: Observable<string>;
    public isReady$: Observable<boolean>;

    private _rootForm: DynamicFormComponent;
    private _metaForm: MetaFormModel;

    constructor(
        protected readonly compiler: Compiler,
        protected readonly injector: Injector,
        protected readonly validationService: ValidationService,
        private readonly formService: FormService,
        private readonly kbService: KbService,
        private readonly dePermissionService: DePermissionService,
        private readonly languageService: LanguageService,
        private readonly actionService: ActionService,
        private readonly metaFormHelperService: MetaFormHelperService
    ) {
        super();
    }

    @Input()
    set rootForm(value: DynamicFormComponent) {
        this._rootForm = value;
    }

    get metaForm(): MetaFormModel {
        return this._metaForm;
    }

    get thisRef(): WorkflowFormComponent {
        return this;
    }

    public ngOnInit(): void {
        this.isReady$ = this.metaFormHelperService
            .loadFormMetaModel(
                this.categoryName,
                this.entity,
                this.formKey,
                this.formId,
                true
            )
            .pipe(
                map(
                    metaForm => {
                        this._metaForm = metaForm;
                        this._countOfControls = this.countNumberOfControls();
                        return true;
                    },
                    err => console.error(err)
                ),
                first()
            );

        this.actionName$ = Observable.fromPromise(
            this.actionService.getAction(this.actionId)
        ).pipe(
            map(action =>
                action.getName().getValue(this.currentLanguage.getId())
            )
        );
    }

    public get currentLanguage(): Language {
        return this._rootForm.currentLanguage;
    }

    public getCurrentLanguage(): Language {
        return this._rootForm.currentLanguage;
    }

    public getLayoutActions(): LayoutAction[] {
        return this._rootForm.layoutActions;
    }

    public isForm(): boolean {
        return true;
    }

    public getEntity(): Entity {
        return this.entity;
    }

    public getActionNotifier(): Subject<ActionNotificationData> {
        return this._rootForm.actionNotifier;
    }

    public doWorkflowAction(): void {
        this._rootForm.actions['doWorkflowWithoutCleaning'](
            this.actionId,
            this._rootForm
        ).subscribe(noop, console.log);
    }

    public addRuntimeComponent(
        controlModel: ControlModel,
        moduleType: Type<object>,
        component: Component,
        cellTitle?: CellTitle
    ): void {
        const rootForm: DynamicFormComponent = this._rootForm;
        const item: Entity = this.entity;
        this._moduleSet.add(moduleType);
        const workflowActionId = this.actionId;
        /* tslint:disable:max-classes-per-file*/
        const cmpClass: Type<object> = class RuntimeComponent
            implements FormRuntimeContext {
            public category = rootForm.getMetaForm().layoutModel.category;
            public form = rootForm;
            public queryParams = rootForm.queryParams;
            public actions = rootForm.actions;
            public languages = rootForm.languages;
            public item = item;
            public cellId = controlModel.id;
            public controlId = controlModel.id;
            public transientFields = rootForm.transientFields;
            public actionNotifier = rootForm.actionNotifier;
            public popupEditMode = rootForm.popupEditMode;
            public contextCategory = this.category;
            public layoutType = DynamicLayoutType.workflowForm;
            public workflowActionId = workflowActionId;
            public formType = FormType.EDIT;
            public title = cellTitle;
            public containerType = rootForm.containerType;

            get workflowActions(): ActionDto[] {
                return this.form.workflowActions;
            }

            get entity(): MainEntity {
                return this.form.entity;
            }

            get dePermissionModel(): DePermission {
                return this.form.metaForm.dePermission;
            }

            get contextEntity(): Entity {
                return rootForm.entity;
            }

            get validations(): Validation[] {
                return this.form.getValidations();
            }

            get currentLanguageId(): number {
                return rootForm.currentLanguage.getId();
            }
        };

        Component(component)(cmpClass);

        this._componentCache.set(controlModel.id, cmpClass);

        if (this._componentCache.size === this._countOfControls) {
            this.loadRuntimeModule();
        }
    }

    public getPredicateContext(): VisibilityConditionContext {
        return {
            entity: this._rootForm.entity,
            actions: this._rootForm.actions,
            item: this.entity,
            popupEditMode: this._rootForm.popupEditMode,
            queryParams: this._rootForm.queryParams,
            isEditForm: this._rootForm.metaForm.formType === FormType.EDIT,
        };
    }

    public getModuleRegistryService(): ModuleRegistryService {
        return this._rootForm.moduleRegistryService;
    }

    public getInitialTabId(): number {
        return undefined;
    }

    public getMetaForm(): MetaFormModel {
        return this.metaForm;
    }

    public dynamicLayoutType(): DynamicLayoutType {
        return DynamicLayoutType.workflowForm;
    }

    public getValidations(): Validation[] {
        return this._rootForm.getValidations();
    }
}
