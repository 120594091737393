import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DeControlsModule} from '@synisys/idm-de-service-client-js';
import {DynamicFormControlsModule} from '@synisys/idm-dynamic-forms-controls';
import {ControlsModule} from '@synisys/idm-ng-controls';
import {FlashMessagesModule} from 'angular2-flash-messages';
import {CellComponent} from './component/cell';
import {ControlComponent} from './component/control';
import {GridComponent} from './component/grid';
import {RowComponent} from './component/row';
import {MaterialModule} from './material.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ControlsModule,
        DynamicFormControlsModule,
        FlashMessagesModule.forRoot(),
        MaterialModule,
        DeControlsModule,
    ],
    declarations: [
        ControlComponent,
        GridComponent,
        RowComponent,
        CellComponent,
    ],

    exports: [ControlComponent, GridComponent, RowComponent, CellComponent],
})
export class DynamicLayoutModule {}
