import {
    Compiler,
    Component,
    ComponentFactory,
    EventEmitter,
    Inject,
    Injector,
    Input,
    ModuleWithComponentFactories,
    NgModule,
    NgModuleRef,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    Type,
} from '@angular/core';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {
    ControlModel,
    GridModel,
    LayoutReadingService,
    ModuleRegistryService,
    PageLayoutModel,
    pageModuleRegistryKey,
    VisibilityConditionContext,
} from '@synisys/idm-dynamic-layout-interpreter';
import {
    DraftPageService,
    PageStructureDto,
} from '@synisys/idm-page-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Observable} from 'rxjs/Observable';
import {ActivatedRoute} from '@angular/router';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {mergeMap, takeUntil} from 'rxjs/operators';
import {DynamicLayout, DynamicLayoutType} from '../dynamic-layout';
import {Subject} from 'rxjs/Subject';
import {MetaFormModel} from '../../service/model';
import {LayoutAction} from '../../service/layout-actions';
import {ActionNotificationData} from '../../concepts';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import './page.component.scss';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {Entity} from '@synisys/idm-de-core-frontend';

@Component({
    moduleId: module.id + '',
    selector: 'dynamic-page',
    templateUrl: 'page.component.html',
})
export class DynamicPageComponent extends DynamicLayout
    implements OnInit, OnChanges, OnDestroy {
    get thisRef(): DynamicPageComponent {
        return this;
    }

    @Input()
    public pageName: string;

    @Input()
    public pageId: number;
    public isReady = false;
    public currentLanguage: Language;
    public filters: object;
    public layout: PageLayoutModel;

    constructor(
        protected readonly injector: Injector,
        protected readonly compiler: Compiler,
        private readonly pageService: DraftPageService,
        private readonly layoutReadingService: LayoutReadingService,
        private readonly activatedRouter: ActivatedRoute,
        private readonly currentLanguageProvider: CurrentLanguageProvider,
        @Inject(pageModuleRegistryKey)
        public readonly moduleRegistryService: ModuleRegistryService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.filters = this.activatedRouter.snapshot.queryParams['filters'];
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['pageId'] || changes['pageName']) {
            this.initPage();
        }
    }

    public ngOnDestroy(): void {
        if (this._runtimeRef) {
            this._runtimeRef.destroy();
        }
        super.ngOnDestroy();
    }

    public addRuntimeComponent(
        controlModel: ControlModel,
        moduleType: Type<object>,
        component: Component
    ): void {
        const layout: DynamicPageComponent = this;
        this._moduleSet.add(moduleType);
        /* tslint:disable:max-classes-per-file*/
        const cmpClass: Type<object> = class RuntimeComponent {
            public form = layout;
            public cellId = controlModel.id;
            public controlId = controlModel.id;

            get filters(): object {
                return layout.activatedRouter.snapshot.queryParams['filters'];
            }

            get currentLanguageId(): number {
                return layout.currentLanguage.getId();
            }
        };
        /* tslint:enable:max-classes-per-file*/

        Component(component)(cmpClass);

        this._componentCache.set(controlModel.id, cmpClass);

        if (this._componentCache.size === this._countOfControls) {
            this.loadRuntimeModule();
        }
    }

    public getActionNotifier(): Subject<ActionNotificationData> {
        return undefined;
    }

    public getInitialTabId(): number {
        return 0;
    }

    public getLayoutActions(): LayoutAction[] {
        return undefined;
    }

    public getMetaForm(): MetaFormModel {
        return undefined;
    }

    public getPredicateContext(): VisibilityConditionContext {
        return undefined;
    }

    public getCurrentLanguage(): Language {
        return this.currentLanguage;
    }

    public isForm(): boolean {
        return false;
    }

    public getEntity(): Entity {
        return undefined;
    }

    public isSubForm(): boolean {
        return false;
    }

    public dynamicLayoutType(): DynamicLayoutType {
        return DynamicLayoutType.subForm;
    }

    public getModuleRegistryService(): ModuleRegistryService {
        return this.moduleRegistryService;
    }

    public getValidations(): Validation[] {
        return undefined;
    }

    public countNumberOfControls(): number {
        return this.countControlsOfGrid(this.layout.content);
    }

    private countControlsOfGrid(grid: GridModel): number {
        let sum = 0;
        if (grid) {
            for (const row of grid.rows) {
                for (const cell of row.cells) {
                    if (cell.controlModel) {
                        sum += 1;
                    } else if (cell.grid) {
                        sum += this.countControlsOfGrid(cell.grid);
                    }
                }
            }
        }

        return sum;
    }

    private initPage(): void {
        this._moduleSet = new Set();
        this._componentCache = new Map();
        this.currentLanguageProvider
            .getCurrentLanguage()
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(language => {
                this.currentLanguage = language;
            }, console.error);
        let pageModel$: Observable<PageStructureDto>;
        if (this.pageId && this.pageId > 0) {
            pageModel$ = Observable.fromPromise(
                this.pageService.getPageStructure(this.pageId)
            );
        } else {
            pageModel$ = Observable.fromPromise(
                this.pageService.getPageStructure(0, this.pageName)
            );
        }
        pageModel$
            .pipe(
                mergeMap((pageDto: PageStructureDto) => {
                    const layoutJson: string = <string>pageDto.getLayout();
                    if (layoutJson !== null && layoutJson !== '') {
                        return this.layoutReadingService.readPage(
                            <string>pageDto.getLayout(),
                            this.pageName
                        );
                    } else {
                        return Observable.of(
                            new PageLayoutModel(this.pageName, undefined)
                        );
                    }
                }),
                takeUntil(this.destroySubject$)
            )
            .subscribe(
                layoutModel => {
                    this.layout = layoutModel;
                    this._countOfControls = this.countNumberOfControls();
                    this.isReady = true;
                },
                error => {
                    console.error(error);
                }
            );
    }
}
