/**
 * Created by edgar.torosyan on 5/2/17.
 */
export class CustomContent {
    private _bindings?: any;
    private _properties?: any;
    private _template?: any;

    constructor(template?: any, bindings?: any, properties?: any) {
        this._bindings = bindings;
        this._properties = properties;
        this._template = template;
    }

    get bindings(): any {
        return this._bindings;
    }

    set bindings(value: any) {
        this._bindings = value;
    }

    get properties(): any {
        return this._properties;
    }

    set properties(value: any) {
        this._properties = value;
    }

    get template(): any {
        return this._template;
    }

    set template(value: any) {
        this._template = value;
    }
}
