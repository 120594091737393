import {Style} from './style.model';

/**
 * @author Vahagn.Lazyan
 * @since 3/3/2017
 * @version 1.0.0
 */

export class Control {
  private _selector: string;
  private _bindings: Bindings;
  private readonly _fields: object;
  private _properties?: any;
  private _style?: Style;
  private _fieldSystemName?: string;
  private _groupSystemName?: string;
  private _content?: object;

  constructor(selector: string,
              bindings: any,
              fields?: object,
              properties?: any,
              style?: Style,
              groupSystemName?: string,
              content?: any) {
    if (fields) {
      this._fields = fields;
      const keys = Object.keys(fields);
      this._fieldSystemName = keys.length === 1 ? fields[keys[0]] : null;
    }
    this._properties = properties;
    this._bindings = bindings;
    this._style = style;
    this._selector = selector;
    this._groupSystemName = groupSystemName;
    this._content = content;
  }

  get fields(): object {
    return this._fields;
  }

  get style(): Style {
    return this._style;
  }

  set style(value: Style) {
    this._style = value;
  }

  get selector(): string {
    return this._selector;
  }

  set selector(value: string) {
    this._selector = value;
  }

  get properties(): any {
    return this._properties;
  }

  set properties(value: any) {
    this._properties = value;
  }

  get bindings(): Bindings {
    return this._bindings;
  }

  set bindings(value: Bindings) {
    this._bindings = value;
  }

  get fieldSystemName(): string {
    return this._fieldSystemName;
  }

  set fieldSystemName(value: string) {
    this._fieldSystemName = value;
  }

  get groupSystemName(): string {
    return this._groupSystemName;
  }

  set groupSystemName(value: string) {
    this._groupSystemName = value;
  }

  get content(): object {
    return this._content;
  }

  set content(value: object) {
    this._content = value;
  }
}

export type Bindings = {
  click: string,
  onClick: string,
  onSelect: string,
  onChange: string,
  onDownload: string,
  categoryName: string,
};
