import {NgModule} from '@angular/core';

import {DeControlsModule} from '@synisys/idm-de-service-client-js';

import {DynamicFormModule}     from '../app.module';
import {
  AlreadyDeletedPageComponent,
  LockErrorPageComponent,
  NoPermissionPageComponent,
  SuccessfullyDeletedPageComponent,
}                              from '../pages';
import {dynamicFormsUiRouting} from './dynamic-forms-ui.routes';

@NgModule({
            imports     : [
              DeControlsModule,
              DynamicFormModule,
              dynamicFormsUiRouting,
            ],
            declarations: [
              AlreadyDeletedPageComponent,
              LockErrorPageComponent,
              NoPermissionPageComponent,
              SuccessfullyDeletedPageComponent,
            ],
            exports     : [
              AlreadyDeletedPageComponent,
              LockErrorPageComponent,
              NoPermissionPageComponent,
              SuccessfullyDeletedPageComponent,
            ],

          })
export class OfflineDynamicFormsUiModule {
}

export * from './dynamic-forms-ui.routes';
