import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material';
import {
    ActivatedRoute,
    DefaultUrlSerializer,
    Router,
    UrlSerializer,
} from '@angular/router';
import {
    CellModel,
    GridModel,
    GridType,
    InterpreterMetaField,
    RowModel,
} from '@synisys/idm-dynamic-layout-interpreter';
import {MetaFieldId} from '@synisys/idm-kb-service-client-js';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {forIn, isEmpty, isNil, uniq} from 'lodash';
import {first, takeUntil} from 'rxjs/operators';
import {ActionNotificationData, ActionNotifierKey} from '../../concepts';
import {LayoutAction} from '../../service/layout-actions';
import {AbstractDestructionSubject} from '../abstract-destruction-subject';
import {DynamicLayout} from '../dynamic-layout';
import {DynamicFormComponent} from '../form';
import './grid.component.scss';

@Component({
    moduleId: module.id + '',
    selector: 'grid',
    templateUrl: 'grid.component.html',
})
export class GridComponent extends AbstractDestructionSubject
    implements OnInit, OnDestroy, AfterViewInit {
    get styleBlock(): string {
        return this._styleBlock;
    }

    @Input()
    set styleBlock(value: string) {
        this._styleBlock = value;
    }

    get selectedTabIndex(): number {
        return this._selectedTabIndex;
    }

    set selectedTabIndex(value: number) {
        this._selectedTabIndex = value;
    }

    get tabsVisibility(): boolean[] {
        return this._tabsVisibility;
    }

    public readonly gridType = GridType;
    @ViewChild('tabGroup', {read: MatTabGroup})
    public tabGroup: MatTabGroup;
    @Input()
    public gridModel: GridModel;
    @Output()
    public tabChanged: EventEmitter<void> = new EventEmitter<void>();
    @Input()
    public layout: DynamicLayout;
    private _styleBlock: string;
    private _selectedTabIndex: number;
    private contentChecked = false;
    private urlSerializer: UrlSerializer;
    /**
     * Indicates if tab has validation errors.
     * If item with some index is false, tab with same index has validation error(s).
     *
     * @type {Array<boolean>}
     * @private
     */
    private _tabsValidity: boolean[] = [];
    private _tabsVisibility: boolean[];

    constructor(private route: ActivatedRoute, private router: Router) {
        super();
        this.urlSerializer = new DefaultUrlSerializer();
    }

    public ngOnInit(): void {
        this._tabsVisibility = this.gridModel.rows.map(() => true);
        this.initTabsValidity();
        if (this.layout.isForm()) {
            const tabId: number = this.layout.getInitialTabId();
            if (!isNil(tabId)) {
                this.selectedTabIndex = tabId;
            }
            if (this.gridModel.type === GridType.TABULAR) {
                this.layout.validationsChanged
                    .pipe(takeUntil(this.destroySubject$))
                    .subscribe(
                        validations => this.checkTabsValidity(validations),
                        console.error
                    );
            }
        }
    }

    public ngAfterViewInit(): void {
        if (this.tabGroup && !this.contentChecked) {
            this.contentChecked = true;
            this.tabGroup.selectedTabChange
                .pipe(takeUntil(this.destroySubject$))
                .subscribe((matTabChangeEvent: MatTabChangeEvent) => {
                    const selectedTabId = matTabChangeEvent.index;
                    if (this.layout.isForm()) {
                        this.layout
                            .getActionNotifier()
                            .next(
                                new ActionNotificationData(
                                    ActionNotifierKey.TAB_CHANGE_ACTION,
                                    selectedTabId
                                )
                            );
                        this.tabChanged.emit();
                    }
                    this.goByRouterUrl(selectedTabId);
                }, console.error);
        }
    }

    public isGridVisible(): boolean {
        if (!this.layout.isForm()) {
            return true;
        }
        return this.gridModel.properties && this.gridModel.properties.visibility
            ? this.gridModel.properties.visibility.predicate
                ? this.gridModel.properties.visibility.predicate(
                      this.layout.getPredicateContext()
                  )
                : true
            : true;
    }

    public getCustomTextValue(row: RowModel): string {
        return row.title.customText[this.layout.getCurrentLanguage().getId()];
    }

    public isRowVisible(rowModel: RowModel): boolean {
        if (!this.layout.isForm()) {
            return true;
        }
        return rowModel.properties.visibility
            ? rowModel.properties.visibility.predicate
                ? rowModel.properties.visibility.predicate(
                      this.layout.getPredicateContext()
                  )
                : true
            : true;
    }

    public isTabInvalid(tabIndex: number): boolean {
        return !this._tabsValidity[tabIndex];
    }

    public validationEmittedFor(controlId: string): void {
        this.gridModel.rows.forEach((row: RowModel, index: number) => {
            const rowIds = this.getRowControlIds(row);
            if (rowIds.find(id => id === controlId)) {
                this._tabsValidity[index] = false;
            }
        });
    }

    public trackByFunc(index: number, row: RowModel): string {
        return row.id;
    }

    public setVisibility(visibility: boolean, index: number): void {
        setTimeout(() => (this.tabsVisibility[index] = visibility));
    }

    private initTabsValidity(): void {
        if (this.gridModel.type === GridType.TABULAR) {
            this.gridModel.rows.forEach(
                (row, index) => (this._tabsValidity[index] = true)
            );
            if (this.layout instanceof DynamicFormComponent) {
                const action: LayoutAction = this.layout.layoutActions.find(
                    act => act.name === 'validationEmittedFor'
                );
                action.func = (controlId: string) => {
                    this.validationEmittedFor(controlId);
                };
                this.layout.actions[action.name] = action.func;
            }
            this.route.fragment.pipe(first()).subscribe((fragment: string) => {
                const tabIndex = Number(fragment);
                if (tabIndex < 0 || tabIndex >= this._tabsValidity.length) {
                    this.selectedTabIndex = 0;
                    this.goByRouterUrl(this.selectedTabIndex);
                } else {
                    this.selectedTabIndex = tabIndex;
                }
            }, console.error);
        }
    }

    private goByRouterUrl(selectedTabIndex: number): void {
        if (
            !(this.layout instanceof DynamicFormComponent) ||
            !this.layout.inDrawer
        ) {
            const parsed = this.urlSerializer.parse(this.router.url);
            parsed.fragment = selectedTabIndex.toString();
            this.router.navigateByUrl(parsed);
        }
    }

    private checkTabsValidity(validations: Validation[]): void {
        if (this.gridModel.type === GridType.TABULAR) {
            let invalidFields: string[] = [];
            validations.forEach((validation: Validation) => {
                invalidFields.push(
                    ...validation
                        .getRelatedMetaFieldIds()
                        .map((relatedMetaFieldId: MetaFieldId) =>
                            relatedMetaFieldId.getSystemName()
                        )
                );
            });
            invalidFields = uniq(invalidFields);
            if (!isEmpty(invalidFields)) {
                this.gridModel.rows.forEach((row: RowModel, index: number) => {
                    const rowFields = this.getRowFields(row);
                    this._tabsValidity[index] = !invalidFields.some(
                        (field: string) => rowFields.indexOf(field) !== -1
                    );
                });
            } else {
                this.initTabsValidity();
            }
        }
    }

    private getRowControlIds(row: RowModel): string[] {
        const ids: string[] = [];
        row.cells.forEach((cell: CellModel) => {
            if (cell.grid) {
                cell.grid.rows.forEach((innerRow: RowModel) => {
                    ids.push(...this.getRowControlIds(innerRow));
                });
            } else {
                ids.push(cell.controlModel.id);
            }
        });
        return ids;
    }

    private getRowFields(row: RowModel): string[] {
        const fields: string[] = [];
        row.cells.forEach((cell: CellModel) => {
            if (cell.grid) {
                cell.grid.rows.forEach((innerRow: RowModel) => {
                    fields.push(...this.getRowFields(innerRow));
                });
            } else {
                forIn(
                    cell.controlModel.fields,
                    (value: InterpreterMetaField) => {
                        if (!isNil(value) && !isNil(value.metaFieldId)) {
                            fields.push(value.metaFieldId.systemName);
                        }
                    }
                );
            }
        });
        return fields;
    }
}
