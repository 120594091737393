/**
 * Created by edgar.torosyan on 4/11/17.
 */
export enum TitleSource {
    CUSTOM = 'custom',
    FIELD = 'field',
    GROUP = 'group',
    MESSAGE = 'msg',
    NONE = 'none',
}
