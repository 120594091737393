import {
    ControlModel,
    ModuleRegistryService,
    VisibilityConditionContext,
} from '@synisys/idm-dynamic-layout-interpreter';
import {
    Compiler,
    Component,
    ComponentFactory,
    EventEmitter,
    Injector,
    ModuleWithComponentFactories,
    NgModule,
    NgModuleRef,
    Type,
} from '@angular/core';
import {LayoutAction} from '../service/layout-actions';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {Subject} from 'rxjs/Subject';
import {ActionNotificationData} from '../concepts';
import {MetaFormModel} from '../service';
import {Validation} from '@synisys/idm-validation-calculation-service-client-js';
import {AbstractDestructionSubject} from './abstract-destruction-subject';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Observable} from 'rxjs/Observable';
import {CellTitle} from './cell/cell.component';
import {Entity} from '@synisys/idm-de-core-frontend';

export enum DynamicLayoutType {
    form,
    page,
    subForm,
    workflowForm,
}

export abstract class DynamicLayout extends AbstractDestructionSubject {
    public validationsChanged: Subject<Validation[]> = new Subject();
    public readyToDrawComponents: EventEmitter<void> = new EventEmitter<void>();
    protected _moduleSet: Set<Type<object>> = new Set<Type<object>>();
    protected _componentCache: Map<string, Type<object>> = new Map();
    protected _countOfControls: number;
    protected _runtimeModuleWithFactory: ModuleWithComponentFactories<object>;
    protected _runtimeRef: NgModuleRef<object>;

    protected abstract compiler: Compiler;
    protected abstract injector: Injector;

    public abstract isForm(): boolean;

    public abstract dynamicLayoutType(): DynamicLayoutType;

    public abstract addRuntimeComponent(
        controlModel: ControlModel,
        moduleType: Type<object>,
        component: Component,
        cellTitle?: CellTitle
    ): void;

    public abstract getPredicateContext(): VisibilityConditionContext;

    public abstract getLayoutActions(): LayoutAction[];

    public abstract getActionNotifier(): Subject<ActionNotificationData>;

    public abstract getMetaForm(): MetaFormModel;

    public abstract getCurrentLanguage(): Language;

    public abstract getModuleRegistryService(): ModuleRegistryService;

    public abstract getValidations(): Validation[];

    public abstract getInitialTabId(): number;

    public abstract getEntity(): Entity;

    public getRuntimeModuleRef(): NgModuleRef<object> {
        return this._runtimeRef;
    }

    public getRuntimeComponentFactory(
        controlModelId: string
    ): ComponentFactory<object> {
        return this._runtimeModuleWithFactory.componentFactories.find(
            f => f.componentType === this._componentCache.get(controlModelId)
        );
    }

    public abstract countNumberOfControls(): number;

    public loadRuntimeModule(): void {
        /* tslint:disable:max-classes-per-file*/

        const moduleSet = this._moduleSet;
        const componentCache = this._componentCache.values();

        @NgModule({
            imports: [FormsModule, CommonModule, ...Array.from(moduleSet)],
            declarations: [...Array.from(componentCache)],
        })
        class RuntimeComponentModule {}

        /* tslint:enable:max-classes-per-file*/
        Observable.fromPromise(
            this.compiler.compileModuleAndAllComponentsAsync(
                RuntimeComponentModule
            )
        ).subscribe((module: ModuleWithComponentFactories<object>) => {
            this._runtimeModuleWithFactory = module;
            this._runtimeRef = module.ngModuleFactory.create(this.injector);
            this.readyToDrawComponents.next();
        }, console.error);
    }
}
