/**
 * @author Vahagn Lazyan.
 * @since 2.1.0
 */

import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {PermissionType} from '@synisys/idm-authorization-client-js';

import {GotoDataEntryComponent} from '../component/portfolio';
import {LockErrorPageComponent} from '../pages/lock-error-page';
import {AlreadyDeletedPageComponent} from '../pages/already-deleted-page';
import {NoPermissionPageComponent} from '../pages/no-permission';
import {DePermissionGuard, LockGuard} from '../service/guards';
import {SuccessfullyDeletedPageComponent} from '../pages/successfully-deleted-page';
import {WarningOnLeaveGuard} from '../service/guards/impl/warning-on-leave.guard';

const appRoutes: Routes = [
    {
        path: ':categoryName/:formName/:formId/:id',
        canActivate: [DePermissionGuard, LockGuard],
        canDeactivate: [WarningOnLeaveGuard],
        component: GotoDataEntryComponent,
    },
    {
        path: ':categoryName/:formName/:formId',
        canActivate: [DePermissionGuard],
        canDeactivate: [WarningOnLeaveGuard],
        component: GotoDataEntryComponent,
        data: {
            categoryPermissionType: PermissionType.ADD,
        },
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'locked',
        component: LockErrorPageComponent,
    },
    {
        path: 'deleted',
        component: AlreadyDeletedPageComponent,
    },
    {
        path: 'no-permission/:categoryPermissionName',
        component: NoPermissionPageComponent,
    },
    {
        path: 'successfully-deleted',
        component: SuccessfullyDeletedPageComponent,
    },
];

export const dynamicFormsUiRouting: ModuleWithProviders = RouterModule.forChild(
    appRoutes
);
