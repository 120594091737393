/**
 * Created by edgar.torosyan on 5/28/17.
 */

import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {Entity} from '@synisys/idm-de-core-frontend';
import {DynamicFormInterface} from '../../service/local';
import {DynamicLayoutType} from '../dynamic-layout';

import './popup-sub-form.component.scss';

export interface PopupData {
    form: DynamicFormInterface;
    formId: string | number;
    formKey: string;
    categoryName: string;
    title: string | {[key: number]: string};
    layoutType: DynamicLayoutType.subForm | DynamicLayoutType.workflowForm;
    entity?: Entity;
    actionId?: number;
}

@Component({
    moduleId: module.id + '',
    selector: 'popup-sub-form',
    templateUrl: 'popup-sub-form.component.html',
})
export class PopupSubFormComponent {
    public dynamicLayoutType = DynamicLayoutType;
    public form: DynamicFormInterface;

    public entity: Entity | undefined;
    public categoryName: string;
    public formId: string | number;
    public formKey: string;
    public popupValidationMessage: MultilingualString;
    public title: string | object;
    public currentLanguageId: number;
    public layoutType:
        | DynamicLayoutType.subForm
        | DynamicLayoutType.workflowForm;
    public actionId: number;

    constructor(
        public dialogRef: MatDialogRef<PopupSubFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PopupData
    ) {
        this.form = data.form;
        this.title = data.title;
        this.formId = data.formId;
        this.categoryName = data.categoryName;
        this.formKey = data.formKey;
        this.entity = data.entity;
        this.layoutType = data.layoutType;
        this.actionId = data.actionId;
        this.currentLanguageId = this.form.currentLanguage.getId();
        this.form.setPopupValidations([]);
    }

    public close() {
        this.dialogRef.close({});
    }

    public getPopupTitle(): string {
        if (typeof this.title === 'string') {
            return this.title;
        } else {
            return this.title[this.currentLanguageId];
        }
    }
}
