import {Subject} from 'rxjs/Subject';
import {OnDestroy} from '@angular/core';

export abstract class AbstractDestructionSubject implements OnDestroy {
  protected destroySubject$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
