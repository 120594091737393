export class NotificationModel {

    public timeoutId: NodeJS.Timer;
    public mode: string;
    public message: string;
    public modeText: string;

    constructor(public visible = false) {
    }

    public onHide(): void {
        this.visible = false;
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
            this.timeoutId = undefined;
        }
    }

    public show(): void {
        if (this.visible) {
            return;
        }
        this.visible = true;
        this.timeoutId = setTimeout(() => {
            this.visible = false;
            this.timeoutId = undefined;
        }, 3500);
    }
}
