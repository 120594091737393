import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {
    DeControlsModule,
    DeSerializationService,
} from '@synisys/idm-de-service-client-js';
import {DocumentAuthProviderService} from '@synisys/idm-document-service-client-js';
import {DynamicFormControlsModule} from '@synisys/idm-dynamic-forms-controls';
import {SisUtilitiesModule} from '@synisys/idm-frontend-shared';
import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {ControlsModule} from '@synisys/idm-ng-controls';
import {ControlsTranslationService} from '@synisys/idm-ng-controls/app/controls/shared/controls-translation.service';
import {FlashMessagesModule} from 'angular2-flash-messages';
import {DynamicFormComponent} from './component/form';
import {PopupEntityComponent, PopupSubFormComponent} from './component/popup';
import {GotoDataEntryComponent} from './component/portfolio';
import {DynamicSubFormComponent} from './component/sub-form';
import {DynamicTemplateModule} from './component/template/dynamic-template.module';
import {WorkflowFormComponent} from './component/workflow-form/workflow-form.component';
import {DeContainerComponent} from './de.container.component';
import {DynamicLayoutModule} from './dynamic-layout.module';
import {MaterialModule} from './material.module';
import {DeDocumentTokenProvider} from './service/impl';
import {RoutingHistoryService} from './service/local';
import {MetaFormHelperService} from './service/local/meta-form-helper.service';

export function controlsTranslationServiceFactory(
    messageService: MessageService
): ControlsTranslationService {
    return new ControlsTranslationService(messageService);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ControlsModule,
        DynamicFormControlsModule,
        DynamicLayoutModule,
        FlashMessagesModule.forRoot(),
        MaterialModule,
        DeControlsModule,
        SisUtilitiesModule,
        DynamicTemplateModule,
    ],
    declarations: [
        DynamicFormComponent,
        DeContainerComponent,
        DynamicSubFormComponent,
        PopupSubFormComponent,
        GotoDataEntryComponent,
        PopupEntityComponent,
        WorkflowFormComponent,
    ],
    entryComponents: [PopupSubFormComponent],
    exports: [
        DynamicFormComponent,
        DeContainerComponent,
        GotoDataEntryComponent,
        DynamicSubFormComponent,
        PopupEntityComponent,
        WorkflowFormComponent,
    ],
    providers: [
        DeSerializationService,
        RoutingHistoryService,
        MetaFormHelperService,
        {
            provide: DocumentAuthProviderService,
            useClass: DeDocumentTokenProvider,
        },
        {
            provide: ControlsTranslationService,
            useFactory: controlsTranslationServiceFactory,
            deps: [MessageService],
        },
    ],
})
export class DynamicFormModule {}
