import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material';
import {
    SisTranslateModule,
    SisUtilitiesModule,
} from '@synisys/idm-frontend-shared';
import {
    IExpressionEvaluationService,
    MultilingualEditorModule,
} from '@synisys/idm-xml-template-builder';
import {DynamicTemplateEditorComponent} from './dynamic-template-editor.component';
import {ExpressionEvaluationService} from './expression-evaluation.service';
import {TemplateBuilderCommunicationService} from './template-builder-communication.service';

@NgModule({
    imports: [
        MultilingualEditorModule,
        SisTranslateModule,
        MatButtonModule,
        CommonModule,
    ],
    declarations: [DynamicTemplateEditorComponent],
    exports: [DynamicTemplateEditorComponent],
    providers: [
        TemplateBuilderCommunicationService,
        {
            provide: IExpressionEvaluationService,
            useClass: ExpressionEvaluationService,
        },
    ],
})
export class DynamicTemplateModule {}
