import {Control} from './control.model';
import {Title} from './title.model';

/**
 * Created by edgar.torosyan on 7/19/17.
 */
export class Column {
  private _title: Title;
  private _control: Control;

  constructor(title: Title, control: Control) {
    this._title = title;
    this._control = control;
  }

  get title(): Title {
    return this._title;
  }

  set title(value: Title) {
    this._title = value;
  }

  get control(): Control {
    return this._control;
  }

  set control(value: Control) {
    this._control = value;
  }
}
