/**
 * @author Vahagn.Lazyan
 * @since 3/2/2017
 * @version 1.0.0
 */

export class Style {
    private _template?: string;
    private _classes?: string[];

    constructor(template?: string, classes?: string[]) {
        this._template = template;
        this._classes = classes;
    }

    get template(): string {
        return this._template;
    }

    set template(value: string) {
        this._template = value;
    }

    get classes(): string[] {
        return this._classes;
    }

    set classes(value: string[]) {
        this._classes = value;
    }
}
