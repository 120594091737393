import {GridModel} from '@synisys/idm-dynamic-layout-interpreter';
import {Logger} from '@synisys/skynet-store-utilities';
import {attempt, isError} from 'lodash';
import {DynamicLayout, DynamicLayoutType} from './dynamic-layout';

export function isJson(str: string): boolean {
    return !isError(attempt(JSON.parse, str));
}

export function isBooleanString(str: string): boolean {
    return str === 'true' || str === 'false';
}

export function countGridControls(grid: GridModel): number {
    let sum = 0;
    if (grid) {
        for (const row of grid.rows) {
            for (const cell of row.cells) {
                if (cell.controlModel) {
                    sum += 1;
                } else if (cell.grid) {
                    sum += countGridControls(cell.grid);
                }
            }
        }
    }
    return sum;
}

export type ContextEntity = 'entity' | 'item';

export function getContextEntity(
    layout: DynamicLayout
): ContextEntity | undefined {
    switch (layout.dynamicLayoutType()) {
        case DynamicLayoutType.form:
        case DynamicLayoutType.workflowForm:
            return 'entity';
        case DynamicLayoutType.subForm:
            return 'item';
        default:
            return undefined;
    }
}

export function createLogger(scope: string): Logger {
    return new Logger('idm-dynamic-forms-ui', scope);
}
