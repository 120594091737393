import {ActionNotifierKey} from './action-notifier-key.enum';

export class ActionNotificationData {

  constructor(private readonly _key: ActionNotifierKey, private readonly _data: object | number) {
  }

  get key(): ActionNotifierKey {
    return this._key;
  }

  get data(): object | number {
    return this._data;
  }
}
